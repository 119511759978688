import _ from 'lodash'
import store from '@/store'
import { JINDUO } from '@/constances/jinduo'
import request from '@/utils/request'
import { slim } from '@/utils/filter'

const platform = {
  [`产品中心`]: JINDUO.PROD_CTR_API,
}
const source = {
  [`产品配套`]: 'accessory/product',
  [`产品`]: 'product',
  [`参数`]: 'parameter',
  [`参数值`]: 'parameter_option',
  [`云盘`]: 'lenovo',
  [`对象存储`]: 'oss/endpoint',
  [`品牌`]: 'brand',
}
const action = {
  [`列表`]: 'list',
  [`列表带值`]: 'cache/list',
  [`有效值列表`]: 'listByAccessoryProduct',
  [`列表带有效值`]: 'cache/listByAp',
  [`详情`]: 'detail',
  [`相关图片`]: 'images',
  [`目录`]: 'getFilePath',
  [`上传`]: 'put-file',
}

const PARAM = {
  BYNAME: {
    JINDUO_ID: 'jinduo',
    FANCY : 'fancy', // '花式' : 51
    GREEN_BODY : 'green_body_process', // '坯体工艺' : 52
    SERIE : 'series_classification', // '系列分类' : 53
    WATER_ABSORPTION : 'water_absorption', // '坯系吸水率种类' : 54
    SPECIAL_PROCESS : 'special_process', // '特殊工艺' : 55
    GLAZE : 'glaze_technology', // '釉面工艺' : 56
    GLOSS : 'gloss', // '光泽度' : 57
    COLOR_SCHEME : 'fancy_color_scheme', // '花式色系' : 58
    SPECIFICATION : 'product_specification', // 产品规格(mm)' : 59
    CATEGORY : 'product_category', // '产品类别' : 60
    STYLE : 'application_style_suggestion', // '应用风格建议' : 61
    SPACE : 'applicable_space', // '适用空间' : 62
  },
  SPECIAL_PARAMS: {
    'series_classification': '系列',
    'product_category': '品类',
    'application_style_suggestion': '风格',
  },
  NO_IMG: 'https://resource.jinduo.com/upload/jinduo/image/20220809/44815a14493d240a6135527c56d83a03.png',
  NO_THUMBNAIL: 'https://resource.jinduo.com/upload/jinduo/image/20220929/ca9004cffb02df3ebf060ca01b4500c2.png',
  NO_VALUE: '暂无',
}

const SETINFO = {
  UNIQUE_KEY: 'platformId',
  UNIQUE_VALUE: 'accessory_product_id',
  PARAM,
  INIT_SET_QUERY_FORM: {
    page: 1, // 页码
    page_size: 100, // 分页数量（默认100）
    main_model_num: null, // 主型号（产品型号）（模糊搜索）
    model_num: null, // 产品型号
    product_name: null, // 产品名称（模糊查询）
    queryAccessoryProductIds: null, // 查询的产品配套数组【"1","2"】(查询和不查询只能存在一个)
    notQueryAccessoryProductIds: null, // 不查询的产品配套数组【"1","2"】
    plan_status: null, // 旧逻辑 产品状态（规划状态，0删除，1主推，2工程定制，3过渡淘汰，4淘汰，5特殊上样）
    // plan_status: '主推产品', // 规划状态 单个模糊搜索(主推产品，工程定制，淘汰产品，无)
    plan_status_string: null, // 产品规划多个搜索 逗号分隔
    // plan_status: '主推', 
    hasParamOptions: 1, // 需要获取产品配套型号的所有参数值（传1）
    params: null, // 参数值数组 [{id:参数主键id（当存在params时必填）,name:参数名称,values:参数值【参数值1的id，参数值2的id】（当存在params时必填）}]
  
    upShelf: null, // 是否上架（0-未上架，1-已上架）
    brand_id: store?.state?.params?.brands?.[PARAM.BYNAME.JINDUO_ID], // 金舵品牌id 注意测试环境正式环境id 差异 COM_TEST_ONLY
  },
  INIT_MODEL_QUERY_FORM: {
    page: 1, // 页码
    page_size: 20, // 分页数量（默认100）
    model_num: null, // 型号模糊搜索
    product_name: null, // 产品名称（模糊查询）
    modelNumber_arr: null, // 产品型号数组【"JD1234","JD2333"】
    thirdSyncModelNumber_arr: null, // 第三方存在的产品型号数组(做过滤此类型号)【"JD1234","JD2333"】
    status_arr: [0,1], // 产品状态【0,1】
    // plan_status: null, // 旧逻辑 产品状态（规划状态，0删除，1主推，2工程定制，3过渡淘汰，4淘汰，5特殊上样）
    plan_status: null, // 规划状态 单个模糊搜索(主推产品，工程定制，淘汰产品，无)
    plan_status_string: null, // 产品规划多个搜索 逗号分隔
    // plan_status: '主推', 
    params: null, // 参数值数组 [{id:参数主键id（当存在params时必填）,name:参数名称,values:参数值【参数值1的id，参数值2的id】（当存在params时必填）}]
  },
  INIT_SETINFO: {
    id: null, // id
    productName: null, // 产品名称
    modelNum: null, // 产品型号（JDM48028S）
    details: null, // 详情图，最多11张
    mainPicture: null, // 主图片1张（图片存名字，全路径手动拼接）
    pictures: null, // 除主图外的其他图片最多11张
    productDescribe: null, // 描述
    upShelf: 0, // 上架:0-下架，1-上架
    sort: null, // 排序
  },
  UPSHELF : {
    0: '未上架',
    1: '已上架',
  },
  API: {
    getBrands: params => request({
      baseURL: `${platform['产品中心']}`,
      url: `/${source['品牌']}/${action['列表']}`,
      method: 'post',
      data: slim(params,['status','page','page_size'],['name']),
    }),
    getModels: params => request({
      baseURL: `${platform['产品中心']}`,
      url: `/${source['产品']}/${action['列表']}`,
      method: 'post',
      data: slim(params,[],['model_num','product_name'],['modelNumber_arr','thirdSyncModelNumber_arr','params','status_arr']),
    }),
    getSets: params => request({
      baseURL: `${platform['产品中心']}`,
      url: `/${source['产品配套']}/${action['列表']}`,
      method: 'post',
      data: slim(params,[],['model_num','product_name'],['params']),
    }),
    getParams: params => request({
      baseURL: `${platform['产品中心']}`,
      url: `/${source['参数']}/${action['列表']}`,
      method: 'post',
      data: slim(params),
    }),
    getParamValues: params => request({
      baseURL: `${platform['产品中心']}`,
      url: `/${source['参数值']}/${action['列表']}`,
      method: 'post',
      data: slim(params),
    }),
    getParamsWithValues: params => request({
      baseURL: `${platform['产品中心']}`,
      url: `/${source['参数']}/${action['列表带值']}`,
      method: 'post',
      data: slim(params),
    }),
    getParamsWithAliveValues: params => request({
      baseURL: `${platform['产品中心']}`,
      url: `/${source['参数']}/${action['列表带有效值']}`,
      method: 'post',
      data: slim(params),
    }),
    getAliveParamValues: params => request({
      baseURL: `${platform['产品中心']}`,
      url: `/${source['参数值']}/${action['有效值列表']}`,
      method: 'post',
      data: slim(params),
    }),
    getModelImages: params => request({
      baseURL: `${platform['产品中心']}`,
      url: `/${source['云盘']}/${action['相关图片']}`,
      method: 'get',
      params: slim(params),
    }),
    getfiles: data => request({
      baseURL: `${platform['产品中心']}`,
      url: `/${source['云盘']}/${action['目录']}`,
      method: 'post',
      data: slim(params),
    }),
  },
}
SETINFO.FUNC = {}

SETINFO.FUNC.getCover = async model_num => {
  const purpose = `获取型号封面: ${model_num}`;
  let cover = SETINFO.PARAM.NO_IMG;
  try {
    console.log('SETINFO.FUNC.getCover', model_num);
    const res = await SETINFO.API.getModelImages({
      model_num, // 产品型号
      thumbtail: 1, // 是否为略缩图 1 是 0 否 默认否
      width: 400, // 略缩图宽度 默认36
      height: 400, // 略缩图高度 默认36
    });
    console.log(`getCover ${model_num} res`,res);
    if (res?.code === 0){
      cover = res?.data?.high_image?.[0] || cover;
    } else {
      throw new Error(res?.message || `${purpose}失败`)
    }
  } catch (error) {
    console.log(`error getCover`, error);
  } finally {
    return cover;
  }
}

SETINFO.FUNC.getModelsByNums = async models => {
  const purpose = `根据型号获取型号产品列表`;
  const params = store.state.params.params;
  try {
    const modelsObj = models.reduce((result,model,index)=>{
      result[model.modelNum] = model;
      return result;
    },{})
    const queryForm = _.cloneDeep(SETINFO.INIT_MODEL_QUERY_FORM);
    queryForm.modelNumber_arr = Object.keys(modelsObj); // COM_TEST_ONLY
    const res = await SETINFO.API.getModels(queryForm);
    console.log(`${purpose} res`, res);
    if (res?.data?.code === 0){
      models = (res?.data?.data?.data || []).map(async model=>{
        const modelParams = Object.values(model.params).reduce((obj,param)=>{
          // const unit = param.name.match(/(?<=(\(|（))(.+?)(?=(\)|）))/g)?.[0]  || ''; // IOS 兼容问题
          obj[`${param.id}`] = Object.values(param.values).reduce((valueArr,value)=>{
            // valueArr.push(`${value}${unit}`);
            valueArr.push(`${value}`);
            return valueArr;
          },[]).join(',')
          return obj;
        },{});
        const modelNum = model?.model_num;
        const localModel = modelsObj[modelNum] || {};
        const productName = model?.product_name || model?.erp_product_name || localModel?.productName;
        const title = `${productName || ''}${productName?' ':''}${modelNum || ''}`;
        const brandName = model?.brand?.name;
        const shortIntro = model?.short_intro || model?.erp_short_intro || localModel?.shortIntro;
        const space = model?.space || model?.erp_space;
        const style = model?.style || model?.erp_style_advise;
        const serie = modelParams[params[SETINFO.PARAM.BYNAME.SERIE].id] || model?.seriesCategoryList?.[0];
        const category = modelParams[SETINFO.PARAM.CATEGORY_ID];
        const specs = modelParams[SETINFO.PARAM.SPEC_ID];
        const cover = model?.pictures?.[0] || model?.mainPicture || await SETINFO.FUNC.getCover();
        return {
          ...localModel,
          ...model,
          productName,
          modelNum,
          title,
          cover,
          brandName,
          shortIntro,
          space,
          style,
          serie,
          specs,
          category,
          modelParams,
          checked: false,
        };
      });
    } else {
      throw new Error(res?.data?.message || `${purpose}失败`)
    }
  } catch (error) {
    console.log(`error getSets`, error);
  } finally {
    return models;
  }
} 

SETINFO.FUNC.getSetsByProducts = async products => {
  const purpose = `根据产品获取配套列表`;
  const params = store.state.params.params;
  let setInfos = [];
  try {
    const allSetInfosObj = products.reduce((result,product,index)=>{
      result[product.ossProductId] = product;
      return result;
    },{})
    const queryForm = _.cloneDeep(SETINFO.INIT_SET_QUERY_FORM);
    queryForm.queryAccessoryProductIds = Object.keys(allSetInfosObj); // COM_TEST_ONLY
    if (!queryForm.queryAccessoryProductIds.length) {
      throw new Error(`${purpose}失败: 无产品数据`)
    }
    const res = await SETINFO.API.getSets(queryForm);
    console.log(`${purpose} res`, res);
    if (res?.data?.code === 0 && res?.data?.data?.data?.length){
      const data =  res.data.data.data;
      const setInfosObj = data.reduce((result, setInfo) => {
        setInfo.accessory_products.forEach(async (model,index,set)=>{
          if (model.is_main) {
            const modelParams = Object.values(model.params).reduce((obj,param)=>{
              // const unit = param.name.match(/(?<=(\(|（))(.+?)(?=(\)|）))/g)?.[0]  || ''; // IOS 兼容问题
              obj[param.id] = Object.values(param.values).reduce((valueArr,value)=>{
                // valueArr.push(`${value}${unit}`);
                valueArr.push(`${value}`);
                return valueArr;
              },[]).join(',')
              return obj;
            },{});
            // setInfo = {...setInfo,...(allSetInfosObj[model[SETINFO.UNIQUE_VALUE]] || {extra:true})};
            Object.assign(setInfo, allSetInfosObj[model[SETINFO.UNIQUE_VALUE]] || {extra:true})
            // console.log('setInfo',model, SETINFO.UNIQUE_VALUE, model[SETINFO.UNIQUE_VALUE], setInfo);
            const productName = setInfo.productName || model?.product?.product_name || model?.product?.erp_product_name;
            const modelNum = setInfo.modelNum || model?.product?.modelNum || model?.product?.model_num;
            const title = !productName || productName == modelNum ? `${modelNum||''}` : `${modelNum} ${productName}`;
            const cover = setInfo?.mainPicture ? (setInfo?.mainPicture.indexOf('http')===0? setInfo?.mainPicture : `https://jinduo-marketing.oss-cn-shenzhen.aliyuncs.com/upload/${setInfo?.mainPicture}`) : model.cover || await SETINFO.FUNC.getCover();
            const specName = (Array.isArray(setInfo?.specsList) ? setInfo.specsList : JSON.parse(setInfo?.specsList || '[]'))[0] || modelParams[SETINFO.PARAM.SPEC_ID];
            const categoryName = (Array.isArray(setInfo?.categoryName) ? setInfo.categoryName : JSON.parse(setInfo?.categoryName || '[]'))?.[0] || setInfo?.productCategoryList?.[0] || modelParams[SETINFO.PARAM.CATEGORY_ID] || SETINFO.PARAM.NO_VALUE;
            const layout = model?.product?.layout || SETINFO.PARAM.NO_VALUE;
            const shortIntro = setInfo.shortIntro || model?.product?.short_intro || model?.product?.erp_short_intro || SETINFO.PARAM.NO_VALUE;
            const featureIntro = setInfo.featureIntro || model?.product?.feature_intro || SETINFO.PARAM.NO_VALUE;
            const serieName = setInfo?.seriesCategoryList?.[0] || modelParams[params[SETINFO.PARAM.BYNAME.SERIE].id] || SETINFO.PARAM.NO_VALUE;
            result.push({
              ...model,
              ...setInfo,
              title,
              cover,
              shortIntro,
              featureIntro,
              layout,
              specName,
              categoryName,
              serieName,
              modelParams,
            })
          }
        })
        return result;
      },{});
      Object.keys(allSetInfosObj).forEach(key => {
        allSetInfosObj[key] = setInfosObj[key] || allSetInfosObj[key]
      });
    } else {
      throw new Error(res?.data?.message || `${purpose}失败`)
    }
  } catch (error) {
    console.log(`error ${purpose}`, error);
  } finally {
    return setInfos;
  }
}
export default SETINFO