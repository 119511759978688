
/**
 * @author:刘志江/zj
 * @date:2020-10-12
 * @descrtion:联系我们模块路由
 */

import { CONTACT_TITLE } from '../../constances/meta'

const contactRoutes = {
  path: 'contact',
  name: 'Contact',
  component: () =>
    import('@/views/contact/index.vue'),
  meta: {
    title: CONTACT_TITLE
  }
}

export default contactRoutes
