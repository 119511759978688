// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--11-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--11-oneOf-1-2!../../../node_modules/postcss-loader/src/index.js??ref--11-oneOf-1-3!../../styles/iconfont.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--11-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--11-oneOf-1-2!../../../node_modules/postcss-loader/src/index.js??ref--11-oneOf-1-3!../../styles/animate.min.css");
exports = ___CSS_LOADER_API_IMPORT___(true);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".page-container[data-v-5b1901ed]{overflow:auto}", "",{"version":3,"sources":["/tmp/www/jinduo/pc/master/src/components/layout/index.vue"],"names":[],"mappings":"AAGA,iCACE,aAAF","file":"index.vue","sourcesContent":["@import url(\"../../styles/iconfont.css\");\n@import \"../../styles/animate.min.css\";\n.page-container {\n  overflow: auto;\n}\n"]}]);
// Exports
module.exports = exports;
