import request from '@/utils/request'

const key = "auth";
// 登录
export function login(data) {
  return request({
    url: `/pc/auth/login`,
    method: 'post',
    data:{
      ...data,
      // api:'a'
    }
  })
}

// 获取已登录用户信息
export function getInfo() {
  return request({
    url: `/pc/auth/me`,
    method: 'get',
    params:{
      
    }
  })
}

// 退出登录
export function logout(data) {
  return request({
    url: `/pc/auth/logout`,
    method: 'post',
    data:{
      ...data,
     
    }
  })
}

// 注册
export function register(data) {
  return request({
    url: `/pc/auth/register`,
    method: 'post',
    data:{
      ...data,
    }
  })
}

// 找回密码
export function findPsw(data) {
  return request({
    url: `/pc/auth/forgetPassword`,
    method: 'post',
    data:{
      ...data,
    }
  })
}

// 获取验证码
export function getSmsCode(data) {
  return request({
    url: `/pc/auth/sendCode`,
    method: 'post',
    data:{
      ...data,
    }
  })
}

  // 修改密码
  export function resetPassword(data) {
    return request({
      url: `/pc/auth/resetPassword`,
      method: 'post',
      data:{
        ...data,
      }
    })
  }

  // 获取我的积分-积分明细-
  export function pointList(data) {
    return request({
      url: `/pc/user/point/log/list`,
      method: 'get',
      params:{
      
      }
    })
  }



