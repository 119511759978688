import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
/* Layout */
import Layout from '@/components/layout'
import homeRoutes from './modules/home'
import bbsRouters from './modules/bbs'
import productRoutes from './modules/product'
import contactRoutes from './modules/contact'
import personalRoutes from './modules/personal'
import downloadRoutes from './modules/download'
import aboutRouters from './modules/about.js'
import storeRoutes from './modules/store'

import cartRouters from './modules/cart.js'
import designRouters from './modules/design.js'
import designerCenterRouters from './modules/designerCenter.js'
import { setTDK } from '../utils/setPageTitleAndMeta'
import { tdkList } from "@/api/home.js"


// import Home from "../views/Home.vue";
// import {
//   getTDK,
// } from '@/utils/auth'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

import {
  MessageBox,
  Message
} from 'element-ui'

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      // 首页
      homeRoutes,
      // 产品
      productRoutes,
      // 论坛
      bbsRouters,
      // 经销支持
      storeRoutes,

      // 联系我们
      contactRoutes,
      // 个人中心
      personalRoutes,
      // 经销商专区
      downloadRoutes,
      // 装修表单
      cartRouters,
      // 设计
      designRouters,
      // 设计师个人中心
      designerCenterRouters,
      // 走进金舵
      aboutRouters,
      {
        path: '/404',
        component: () => import('@/views/404/index'),
        hidden: true
      }
    ]
  },
  { path: '*', redirect: '/404', hidden: true },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach(async (to, from, next) => {
  try{
    const TDKStorage= localStorage.getItem(`tdkList`)
    if(TDKStorage){
       const TDK = JSON.parse(TDKStorage);
       const {timestemp} = TDK;
       if (new Date().getTime() - timestemp <= 1000 * 60 * 60 * 24) {
        checkTDK(TDK, to) 
       } else {
        localStorage.removeItem(`tdkList`);
        location.reload();
       }
    }else{
      const res = await tdkList({
        page : 1,
        page_size: 9999
      })
      const TDK = res.data.data.reduce((result,item)=>{
        result[item.id] = item;
        return result;
      },{});
      TDK.timestemp = new Date().getTime();
      console.log("getListByTDK TDK",TDK);
      localStorage.setItem(`tdkList`, JSON.stringify(TDK));
      checkTDK(TDK, to) 
    }

     console.log('router.beforeEach',to, from);
     if (to.path.indexOf('/designerCenter')>-1) {
       if (store.state.user?.designer?.designer_detail?.id) {
         if (to.path==='/designerCenter/settings/info') {
           next()
         } else {
           const {
             user_head,
             user_nickname,
             address_province,
             address_city,
             address_area,
             designer_detail: { 
               work_age,
               rel_service_types,
               rel_styles,
               self_introduce
             }
           } = store.state.user?.designer||{};
           if ( user_head && user_nickname && address_province && address_city  && work_age && self_introduce && rel_service_types?.length && rel_styles?.length) {
             console.log('设计师资料完善');
             next()
           } else {
             Message({
               message: `请先完善设计师资料`,
               type: 'info',
               duration: 5 * 1000
             })
             next('/designerCenter/settings/info')
           }
         }
       } else {
         Message({
           message: `请先登录`,
           type: 'error',
           duration: 2 * 1000
         })
         next('/')
       }
     } else {
       next()
     }
  }catch(error){
    console.log(error)
  }
})

function checkTDK(tdkList, to){
  let tdk = {};
  if (to.path.indexOf('/about/') != -1) {
    const query = to.params.query;
    switch (query) {
      case 'intro':
        tdk = tdkList[3];
        break;
      case 'progress':
        tdk = tdkList[4];
        break;      
      case 'honour':
        tdk = tdkList[5];
        break; 
      default:
        tdk = tdkList[6];
        break;
    }
  }
  else if(to.path.indexOf('/product/') != -1){
    tdk = tdkList[2] || {};
  }
  else{
    tdk = tdkList[to.meta.id] || {};
  }
  console.log("tdk", tdk);
  setTDK(tdk)
}

router.afterEach((to, from) => {
  console.log(to.path, from.path)
  if (to.path !== from.path && from.path !== '/') {
    location.reload()
  }
  store.commit('user/CHANGE_NAV', to.path)

  // let type=1;
  // if(to.path.includes('product'))
})

/* const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
 */
// router.afterEach((to,from)=>{
//   if(to.path=='/product/list'&&(from.path=='/design'||from.path=='/store')) {
//     location.reload();
//   }
// })
export default router
