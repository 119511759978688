
const state = {
  test: null
}

const mutations = {
  SET_TEST(state, test) {
    state.test = test;
  },
}

const actions = {
  setTest({ commit}, test) {
    commit('SET_TEST', test)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}