import axios from 'axios'
import Qs from 'qs'
import _ from 'lodash'
import {
  MessageBox,
  Message
} from 'element-ui'
import store from '@/store'
import {
  getToken
} from '@/utils/auth'



const formatParams = (params) => {
  // 过滤空值
  Object.keys(params).map(key => {
    if (params[key] === undefined || params[key] === null || (params[key] === '' && key!=='ku_photo_url')) {
      delete params[key]
    }
  })
  return params
}

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 120 * 1000, // request timeout
  paramsSerializer: function (params) {
    return Qs.stringify(formatParams(params), {
      arrayFormat: 'brackets'
    })
  }
})

// request interceptor
service.interceptors.request.use(
  config => {

    // console.log('000', process.env);
    if (config.data) {
      config.data = formatParams(config.data)
    }
    if ((config.params) ?.hasOwnProperty("api") || (config.data) ?.hasOwnProperty("api")) {
      let api = '';
      if ((config.params) ?.hasOwnProperty("api")) {
        api = config.params.api;
        delete config.params.api;
      } else if ((config.data) ?.hasOwnProperty("api")) {
        api = config.data.api;
        delete config.data.api;
      }
      config.baseURL = '/dev-api-' + api;
    }
    // do something before request is sent
    config.withCredentials = true
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // code 不是0 则是错误
    if (res.code !== 0) {
      /* Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      }) */
      MessageBox.alert(`<div style="text-align: center;height: 150px;font-size: 20px; margin-top: 50px;overflow: hidden;text-overflow: ellipsis;"><i class="el-icon-error" style="color: #e84c4c;margin-right: 10px;"></i>${res.message}</div>`, '', {
        showCancelButton: false,
        dangerouslyUseHTMLString: true,
        showConfirmButton: false,
        closeOnClickModal: false,
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log({
      error
    })
    // for debug
    // if (_.get(error, ['response', 'status']) === 401) {
    if (error?.response?.status === 401) {
      store.dispatch('user/resetToken').then(() => {
        // location.href="#/"
        store.commit("user/SWITCH_LOGIN", true);
        // location.reload()
      })

      return Promise.reject({
        message: '登录过期，请重新登录。',
        code: 401
      })
    } else {
      if (error ?.response ?.data ?.message) {
        Message({
          message: error ?.response ?.data ?.message,
          type: 'error',
          duration: 5 * 1000
        })
      } else if (String(error).includes('timeout')) {
        Message({
          message: `网络等待超过最大时间！`,
          type: 'error',
          duration: 5 * 1000
        })
      } else {
        Message({
          message: `网络拥堵，请稍后再试！`,
          type: 'error',
          duration: 5 * 1000
        })
      }
    }
    return Promise.reject(error)
  }
)

export default service