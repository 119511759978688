/**
 * @author:刘志江/zj
 * @date:2020-10-22
 * @descrtion:经销模块路由
 */

import { STORE_TITLE } from '../../constances/meta'

const storeRoutes = {
  path: 'store/:query',
  name: 'Store',
  component: () => import('@/views/store'),
  meta: {
    title: STORE_TITLE,
    id:9
  }
}

export default storeRoutes
