<template>
  <div class="fixed-tools-bar">
    <section class="sidebar_menu" :class="{ show: show }">
      <div class="sidebar_menu_flexBox">
        <ul class="sidebar_menu_list">
          <li class="qrcode_img">
            <img
              class="wechat_qrcode_pic"
              :src="'https://jinduo-marketing.oss-cn-shenzhen.aliyuncs.com/upload/20241210/45d246893aa619d4fe9da3645a07513a.jpg'|getImgUrl"
              alt=""
            />
          </li>
          <li class="sidebar_menu_listItem wechat_qrcode">
            <a href="javascript:void(0);"><i class="iconfont">&#xe62d;</i></a>
          </li>
          <li class="sidebar_menu_listItem">
            <a
              target="blank"
              href="tencent://message/?uin=1915573298&amp;Site=网站客服&amp;Menu=yes"
              rel="nofollow"
            >
              <i class="iconfont">&#xe61e;</i>
            </a>
          </li>
          <li class="contact_phoneNum">
            <span class="contact_tel">0757-85310888</span>
          </li>
          <li class="sidebar_menu_listItem contact">
            <a href="javascript:void(0);"><i class="iconfont">&#xe649;</i></a>
          </li>
          <li class="sidebar_menu_listItem return_top" @click="goTop">
            <a href="javascript:void(0);"><i class="iconfont">&#xe62b;</i></a>
          </li>
        </ul>
        <div class="menu_icon_box">
          <div class="menu_icon"><i class="iconfont">&#xe601;</i></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false
    };
  },
  created() {
    // 监听window滚动
    window.addEventListener("scroll", e => {
      let top = document.documentElement.scrollTop;

      if (top > 450) {
        this.show = true;
      } else {
        this.show = false;
      }
    });
  },

  mounted() {
    this.$nextTick(() => {
      this._initJqery();
    });
  },

  methods: {
    // 返回顶部
    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },

    // 初始化jquery
    _initJqery() {
      $(".menu_icon").click(function() {
        $(".sidebar_menu_list").fadeToggle();
      });
      // 侧边栏微信二维码click效果
      $(".wechat_qrcode").click(function() {
        $(this).css("transition", "all 1s");
        $(this).css("transform", "rotateY(180deg)");
        $(this).css("backface-visibility", "hidden");
        $(".qrcode_img")
          .stop(true, false)
          .fadeIn(1100);
        if ($(".contact_phoneNum").is(":hidden")) {
          return;
        } else {
          $(".contact_phoneNum")
            .stop(true, false)
            .fadeOut();
          $(".contact").css("backface-visibility", "block");
          $(".contact").css("transition", "all 1s");
          $(".contact").css("transform", "rotateY(0deg)");
        }
      });

      $(".qrcode_img").click(function() {
        $(this)
          .stop(true, false)
          .fadeOut();
        $(".wechat_qrcode").css("backface-visibility", "block");
        $(".wechat_qrcode").css("transition", "all 1s");
        $(".wechat_qrcode").css("transform", "rotateY(0deg)");
      });

      // 侧边栏电话号码click效果
      $(".contact").click(function() {
        $(this).css("transition", "all 1s");
        $(this).css("transform", "rotateY(180deg)");
        $(this).css("backface-visibility", "hidden");
        $(".contact_phoneNum")
          .stop(true, false)
          .fadeIn(1100);
        if ($(".qrcode_img").is(":hidden")) {
          return;
        } else {
          $(".qrcode_img")
            .stop(true, false)
            .fadeOut();
          $(".wechat_qrcode").css("backface-visibility", "block");
          $(".wechat_qrcode").css("transition", "all 1s");
          $(".wechat_qrcode").css("transform", "rotateY(0deg)");
        }
      });

      $(".contact_phoneNum").click(function() {
        $(this)
          .stop(true, false)
          .fadeOut();
        $(".contact").css("backface-visibility", "block");
        $(".contact").css("transition", "all 1s");
        $(".contact").css("transform", "rotateY(0deg)");
      });
    }
  }
};
</script>

<style lang="less">
@import "../../styles/footer.less";
@import "../../styles/slideBar_menu.less";

.sidebar_menu {
  -webkit-transition: all 1s;
  display: block !important;
  opacity: 0;
  bottom: 350px !important;
  z-index: 2 !important;
}

.sidebar_menu.show {
  opacity: 1;
}
</style>
