<template>
  <div class="page-container">
    <HeadBar />
    <TipModal />
    <Login v-if="showConfirmModal" />
    <router-view />
    <FootBar />
    <sidebar></sidebar>
  </div>
</template>

<script>
// @ is an alias to /src
import HeadBar from "@/components/header";
import Login from "@/components/login";
import FootBar from "@/components/footer";
import TipModal from "@/components/tipModal.vue";
import sidebar from "@/components/slideBar/index.vue";
import {
    mapState
  } from "vuex";
export default {
  name: "Layout",
  components: {
    sidebar,
    HeadBar,
    Login,
    TipModal,
    FootBar
  },
  computed: {
    ...mapState('user', ["showConfirmModal"])
  },
  
};
</script>

<style lang="less" scoped>
@import url("../../styles/iconfont.css");
@import "../../styles/animate.min.css";
.page-container{
  overflow: auto;
}
</style>

