export const COMMON_TITLE = '金舵瓷砖官网_ 广东省名牌产品'

export const COMMON_DESCRIPTION = '广东省名牌产品金舵瓷砖是一家专业生产抛光砖、金刚釉、大理石、仿古砖和内墙砖等瓷质砖的大型陶瓷生产企业，先后获得广东省名牌产品、广东省著名商标、广东省守合同重信用企业等多项殊荣。'

export const COMMON_KEYWORDS = '广东省名牌产品金舵瓷砖是一家专业生产抛光砖、金刚釉、大理石、仿古砖和内墙砖等瓷质砖的大型陶瓷生产企业，先后获得广东省名牌产品、广东省著名商标、广东省守合同重信用企业等多项殊荣。'

export const PRPDUCT_TITLE = '产品中心'

export const PRODUCT_KEYWORDS = '尚韵石系列 N次方系列 大于系列 金刚原石系列 完全玻化抛光砖系列 橄榄树系列 美颜系列 云石代系列 岁墨系列 印象都市系列 C元素系列 公共配件 ins风 轻奢 现代简约 北欧风 新中式 古典欧式 简欧式 田园风 后现代'

export const PRODUCT_DESCRIPTION = '广东省名牌产品金舵瓷砖是一家专业生产抛光砖、金刚釉、大理石、仿古砖和内墙砖等瓷质砖的大型陶瓷生产企业，先后获得广东省名牌产品、广东省著名商标、广东省守合同重信用企业等多项殊荣。'

export const PRODUCT_DEFAULT_META = {
  title: PRPDUCT_TITLE,
  contents: {
    keywords: PRODUCT_KEYWORDS,
    description: PRODUCT_DESCRIPTION
  }
}

export const ABOUT_TITLE = '走进金舵'

export const ABOUT_KEYWORDS = `佛山市金舵陶瓷有限公司位于佛山禅城区“中国建陶第一镇”——南庄。金舵瓷砖多年来坚持以质取胜、改革创新的可持续发展理念，成长为一家集研发、生产、销售三位一体的创新型绿色陶瓷智造企业。依托广东佛山、广西梧州两大生产基地的国际化高端生产设备和技术，金舵瓷砖秉承匠心制造“四高”（高品位、高品质、高防护、高价值）瓷砖产品及辅材，产品线丰富齐全，涵盖抛光砖、内墙砖、全抛釉、仿古砖、通体大理石、透水砖等多个品类。
作为首次荣获“中国名牌产品”荣誉，27年来金舵品牌凭借卓越的品质口碑和创新性设计营销服务，载誉累累。新航道，大未来。面对新时代，金舵将大力推行绿色创新智造，以更高、更新、更强的技术不断精进产品，继续践行设计营销理念，以帮助更多消费者打造“所见即所得”的美好人居。
`

export const ABOUT_DESCRIPTION = `佛山市金舵陶瓷有限公司位于佛山禅城区“中国建陶第一镇”——南庄。金舵瓷砖多年来坚持以质取胜、改革创新的可持续发展理念，成长为一家集研发、生产、销售三位一体的创新型绿色陶瓷智造企业。依托广东佛山、广西梧州两大生产基地的国际化高端生产设备和技术，金舵瓷砖秉承匠心制造“四高”（高品位、高品质、高防护、高价值）瓷砖产品及辅材，产品线丰富齐全，涵盖抛光砖、内墙砖、全抛釉、仿古砖、通体大理石、透水砖等多个品类。
作为首次荣获“中国名牌产品”荣誉，27年来金舵品牌凭借卓越的品质口碑和创新性设计营销服务，载誉累累。新航道，大未来。面对新时代，金舵将大力推行绿色创新智造，以更高、更新、更强的技术不断精进产品，继续践行设计营销理念，以帮助更多消费者打造“所见即所得”的美好人居。
`

export const ABOUT_DEFAULT_META = {
  title: ABOUT_TITLE,
  contents: {
    keywords: ABOUT_KEYWORDS,
    description: ABOUT_DESCRIPTION
  }
}

export const CONTACT_TITLE = '联系我们'

export const STORE_TITLE = '经销支持'
