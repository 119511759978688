/**
 * @author:刘志江/zj
 * @date:2020-10-16
 * @descrtion:产品模块路由
 */

import { PRODUCT_DEFAULT_META } from '../../constances/meta'

const productRoutes = {
  path: 'product',
  name: 'Product',
  component: () => import('@/views/product/index'),
  redirect: '/product/list',
  children: [
    // {
    //   path: 'list',
    //   name: 'ProductList',
    //   component: () => import('@/views/product/list'),
    //   meta: {
    //     ...PRODUCT_DEFAULT_META
    //   }
    // },
    // 产品系列的列表页
    {
      path: 'serie/:query',
      name: 'ProductSerie',
      meta: {
        ...PRODUCT_DEFAULT_META
      },
      component: () => import('@/views/product/serie')
    },
    // 其他分类的列表页
    {
      path: 'list/:query',
      name: 'ProductList',
      meta: {
        ...PRODUCT_DEFAULT_META
      },
      component: () => import('@/views/product/list')
    },
    {
      path: 'detail/:id.html',
      name: 'ProductDetail',
      component: () => import('@/views/product/detail')
    }
  ]
}

export default productRoutes
