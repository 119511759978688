var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed-tools-bar"},[_c('section',{staticClass:"sidebar_menu",class:{ show: _vm.show }},[_c('div',{staticClass:"sidebar_menu_flexBox"},[_c('ul',{staticClass:"sidebar_menu_list"},[_c('li',{staticClass:"qrcode_img"},[_c('img',{staticClass:"wechat_qrcode_pic",attrs:{"src":_vm._f("getImgUrl")('https://jinduo-marketing.oss-cn-shenzhen.aliyuncs.com/upload/20241210/45d246893aa619d4fe9da3645a07513a.jpg'),"alt":""}})]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('li',{staticClass:"sidebar_menu_listItem return_top",on:{"click":_vm.goTop}},[_vm._m(4)])]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"sidebar_menu_listItem wechat_qrcode"},[_c('a',{attrs:{"href":"javascript:void(0);"}},[_c('i',{staticClass:"iconfont"},[_vm._v("")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"sidebar_menu_listItem"},[_c('a',{attrs:{"target":"blank","href":"tencent://message/?uin=1915573298&Site=网站客服&Menu=yes","rel":"nofollow"}},[_c('i',{staticClass:"iconfont"},[_vm._v("")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"contact_phoneNum"},[_c('span',{staticClass:"contact_tel"},[_vm._v("0757-85310888")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"sidebar_menu_listItem contact"},[_c('a',{attrs:{"href":"javascript:void(0);"}},[_c('i',{staticClass:"iconfont"},[_vm._v("")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0);"}},[_c('i',{staticClass:"iconfont"},[_vm._v("")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu_icon_box"},[_c('div',{staticClass:"menu_icon"},[_c('i',{staticClass:"iconfont"},[_vm._v("")])])])
}]

export { render, staticRenderFns }