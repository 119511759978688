export function login() {
  return fnc()
}

export function checkLogin(v) {
  // const v = true
  if (v) {
    return true
  } else {
    /* openGlobalModal('#216290', '登录后才可使用加入装修清单、智能搭配方案、问答、晒家、评论等相关功能。')
    $('#Global_Tip_Modal').foundation('open');
    $('#Global_Tip_Modal').find('.config_btn').on('click', function () {
      $('#loginModal').foundation('open');
    }) */
  }
}
// // 封装全局模态框
function openGlobalModal(iconCol, text) {
  $('#Global_Tip_Modal').on('open.zf.reveal', function () {
    $('#Global_Tip_Modal').find('.iconfont').addClass('icon-success').css('color', iconCol);
    $('#Global_Tip_Modal').find('.open_tip').css('width', '80%');
    $('#Global_Tip_Modal').find('.tip_text').html(text);
  })
}

// var flag = {state:false};
export var loginFlag = false;
export var registeredFlag = false;



function fnc() {
  // foundation初始化
  // 点击登录弹出提示模态框
  /* $(document).on('click', '.loginBtn', function () {
    openGlobalModal('#216290', '登录后才可使用加入装修清单、智能搭配方案、问答、晒家、评论等相关功能。');
    $('#Global_Tip_Modal').foundation('open');
    $('#Global_Tip_Modal').find('.config_btn').on('click', function () {
      $('#loginModal').foundation('open');
    })
  }) */

  // // 封装全局模态框
  /* function openGlobalModal(iconCol, text) {
    $('#Global_Tip_Modal').on('open.zf.reveal', function () {
      $('#Global_Tip_Modal').find('.iconfont').addClass('icon-success').css('color', iconCol);
      $('#Global_Tip_Modal').find('.open_tip').css('width', '80%');
      $('#Global_Tip_Modal').find('.tip_text').html(text);
    })
  } */

  // 登录滑动验证
  function loginSlide() {
    var $login_container = $('#login_modal_slide');
    $login_container.slideToUnlock({
      width: 273,
      height: 40,
      successFunc: function () {
        var login_modal_handle = $('#login_modal_slide').find('.slide-to-unlock-handle');
        login_modal_handle.find('.iconfont').removeClass('icon-shuangjiantou-copy').addClass('icon-chenggong').css('color', '#c3a78c');
        $('.login_slide_tips').slideUp();
        loginFlag = true;
      },
    });
  }

  loginSlide();

  // 注册滑动验证
  function registeredSlide() {
    var $registered_container = $('#registered_modal_slide');
    $registered_container.slideToUnlock({
      width: 273,
      height: 40,
      successFunc: function () {
        var registered_modal_handle = $('#registered_modal_slide').find('.slide-to-unlock-handle');
        registered_modal_handle.find('.iconfont').removeClass('icon-shuangjiantou-copy').addClass('icon-chenggong').css('color', '#c3a78c');
        registeredFlag = true;
        $('.registered_slide_tips').slideUp();
      }
    });
  }

  registeredSlide();


  // 登录模块

  // 判断用户名非空及是否符合要求（手机号或邮箱）
  function valiDataUserNameNull() {
    // var phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/;
    // var emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var regResult = $('.login_modal_userName').val();
    if (regResult == '') {
      $('.userName_tips').find('.tips_text').text('帐号不能为空')
      $('.userName_tips').slideDown();
      return false;
    }
    // else if (!phoneReg.test(regResult) && !emailReg.test(regResult)) {
    //   $('.userName_tips').find('.tips_text').text('请输入正确的帐号格式：手机号或邮箱')
    //   $('.userName_tips').slideDown();
    //   return false;
    // }
    else {
      $('.userName_tips').slideUp();
      return true;
    }
  }

  // 判断密码非空和是否符合要求（长度：8-20,数字、英文小写和大写字母）
  function valiDataPassWordNull() {
    var reg = /^[0-9A-Za-z]{8,20}$/;
    var passWord = $('.login_modal_passWord').val();
    if (passWord == '') {
      $('.passWord_tips').find('.tips_text').text('密码不能为空')
      $('.passWord_tips').slideDown();
      return false;
    } else if (!reg.test(passWord)) {
      $('.passWord_tips').find('.tips_text').text('需要8-20位字母、数字的组合，请重新输入')
      $('.passWord_tips').slideDown();
      return false;
    } else {
      $('.passWord_tips').slideUp();
      return true;
    }
  }


  // 失去焦点判断非空
  $('.login_modal_userName').blur(function (e) {
    e.preventDefault();
    var userNameValue = $('.login_modal_userName').val();
    if (userNameValue === 'jinduo') {
      $('.userName_tips').slideUp();
      return true
    } else {
      valiDataUserNameNull();
    }
  });

  $('.login_modal_passWord').blur(function (e) {
    e.preventDefault();
    var userNameValue = $('.login_modal_userName').val();
    if (userNameValue === 'jinduo' || 'jinduo1993') {
      $('.passWord_tips').slideUp();
      return true;
    } else {
      valiDataPassWordNull();
    }
  });

  // 登录模态框关闭时，清空错误提示
  $('#loginModal').on('closed.zf.reveal', function () {
    $('#submitId').attr('disabled', false);
    $('.userName_tips').hide();
    $('.passWord_tips').hide();
    loginSlide();

  });


  $('.close-button').on('click', function () {
    // console.log('close45');
    // location.reload()

  });

  // 监听输入框里面的内容，进行按钮的高亮
  $('.login_modal_userName').bind('input propertychange', function () {
    if ($(this).val() != '' && $('.login_modal_passWord').val() != '') {
      $('.submit').css('color', '#fff');
    } else {
      $('.submit').css('color', '#333');
    }
  });
  $('.login_modal_passWord').bind('input propertychange', function () {
    if ($(this).val() != '' && $('.login_modal_userName').val() != '') {
      $('.submit').css('color', '#fff');
    } else {
      $('.submit').css('color', '#333');
    }
  })

  // 注册模块
  // 判断手机号非空及是否符合要求（11位数字）
  function valiDataRegPhoneNull() {
    var reg = /^[1][3,4,5,7,8][0-9]{9}$/;
    var regPhone = $('.registered_modal_phone').val();
    if (regPhone == '') {
      $('.registered_phone_tips').find('.registered_tips_text').text('手机号不能为空')
      $('.registered_phone_tips').slideDown();
      return false;
    } else if (!reg.test(regPhone)) {
      $('.registered_phone_tips').find('.registered_tips_text').text('请输入正确的手机号格式')
      $('.registered_phone_tips').slideDown();
      return false;
    } else {
      $('.registered_phone_tips').slideUp();
      return true;
    }
  }

  // 判断邮箱非空及是否符合要求（需要符合普通邮箱的验证规则）
  function valiDataRegEmailNull() {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var regEmail = $('.registered_modal_email').val();
    if (regEmail == '') {
      $('.registered_email_tips').find('.registered_tips_text').text('邮箱不能为空')
      $('.registered_email_tips').slideDown();
      return false;
    } else if (!reg.test(regEmail)) {
      $('.registered_email_tips').find('.registered_tips_text').text('请输入正确的邮箱格式')
      $('.registered_email_tips').slideDown();
      return false;
    } else {
      $('.registered_email_tips').slideUp();
      return true;
    }
  }

  // 判断密码非空和是否符合要求（长度：8-20,数字、英文小写和大写字母）
  function valiDataRegPassWordNull() {
    var reg = /^[0-9A-Za-z]{8,20}$/;
    var regPassWord = $('.registered_modal_passWord').val();
    if (regPassWord == '') {
      $('.registered_passWord_tips').find('.registered_tips_text').text('密码不能为空')
      $('.registered_passWord_tips').slideDown();
      return false;
    } else if (!reg.test(regPassWord)) {
      $('.registered_passWord_tips').find('.registered_tips_text').text('需要8-20位字母、数字的组合，请重新输入')
      $('.registered_passWord_tips').slideDown();
      return false;
    } else {
      $('.registered_passWord_tips').slideUp();
      return true;
    }
  }

  // 判断再次输入的密码跟密码是否相符
  function valDataRegPassWordConfirmNull() {
    var regPassWord = $('.registered_modal_passWord').val();
    var regPassWordConfirm = $('.registered_modal_passWordConfirm').val();
    if (regPassWordConfirm != regPassWord) {
      $('.registered_passWordConfirm_tips').find('.registered_tips_text').text('两次密码输入不一样');
      $('.registered_passWordConfirm_tips').slideDown();
      return false;
    } else {
      $('.registered_passWordConfirm_tips').slideUp();
      return true;
    }
  }

  // 失去焦点判断非空
  $('.registered_modal_phone').blur(function (e) {
    e.preventDefault();
    valiDataRegPhoneNull();
  });

  $('.registered_modal_email').blur(function (e) {
    e.preventDefault();
    valiDataRegEmailNull();
  });

  $('.registered_modal_passWord').blur(function (e) {
    e.preventDefault();
    valiDataRegPassWordNull();
  });

  $('.registered_modal_passWordConfirm').blur(function (e) {
    e.preventDefault();
    valDataRegPassWordConfirmNull();
  });

  // 注册模态框关闭时，清空错误提示
  $('#registeredModal').on('closed.zf.reveal', function () {
    $('#regSubmitId').attr('disabled', false);
    $('.registered_phone_tips').hide();
    $('.registered_email_tips').hide();
    $('.registered_passWord_tips').hide();
    $('#Password_level').hide();
    $('.registered_passWordConfirm_tips').hide();
    registeredSlide();
  });

  // 密码强度验证
  $('.registered_modal_passWord').keyup(function () {
    if ($(this).val() == '') {
      $('.Password_strength').slideUp();
      $('.pw_weak').addClass('pw_weak_active');
      $('.pw_middle').removeClass('pw_middle_active');
      $('.pw_strong').removeClass('pw_strong_active');
      $('.pw_level_text').text('');
    } else {

      var strongRegex = new RegExp("^[0-9A-Za-z]{11,}$");
      var mediumRegex = new RegExp("^[0-9A-Za-z]{9,}$");
      var enoughRegex = new RegExp("^[0-9A-Za-z]{8,}$");


      if (false == enoughRegex.test($(this).val())) {
        //密码大于八位的时候，密码强度图片为灰色，强度最低
        $('.Password_strength').slideDown();
        $('.pw_weak').addClass('pw_weak_active');
        $('.pw_middle').removeClass('pw_middle_active');
        $('.pw_strong').removeClass('pw_strong_active');
        $('.pw_level_text').text('低');
      } else if (strongRegex.test($(this).val())) {
        //密码大于十六位的时候，密码强度图片为最深色,强度最强
        $('.pw_weak').addClass('pw_weak_active');
        $('.pw_middle').addClass('pw_middle_active');
        $('.pw_strong').addClass('pw_strong_active');
        $('.pw_level_text').text('高');
      } else if (mediumRegex.test($(this).val())) {
        //密码大于十二位的时候，密码强度图片为主题色，强度中等
        $('.pw_middle').addClass('pw_middle_active');
        $('.pw_weak').addClass('pw_weak_active');
        $('.pw_strong').removeClass('pw_strong_active');
        $('.pw_level_text').text('中');
      }
      return true;
    }
  });

  // 密码重置模块

  // 点击发送验证码倒计时
  var countdown = 60;

  function settime(obj) {
    if (countdown == 0) {
      obj.attr('disabled', false);
      obj.val('发送验证码');
      countdown = 60;
      return;
    } else {
      obj.attr('disabled', true);
      obj.val('(' + countdown + ')重新发送');
      countdown--;
    }
    setTimeout(function () {
      settime(obj)
    }, 1000)
  }

  // 判断帐号非空及是否符合要求（11位数字）
  function valiDataPwdResetAccountNull() {
    var pwdResetPhone = /^[1][3,4,5,7,8][0-9]{9}$/;
    var pwdResetEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var pwdResetResult = $('.pwdReset_modal_account').val();
    if (pwdResetResult == '') {
      $('.pwdReset_account_tips').find('.pwdReset_tips_text').text('帐号不能为空')
      $('.pwdReset_account_tips').slideDown();
      return false;
    } else if (!pwdResetPhone.test(pwdResetResult) && !pwdResetEmail.test(pwdResetResult)) {
      $('.pwdReset_account_tips').find('.pwdReset_tips_text').text('请输入正确的帐号格式：手机号或邮箱');
      $('.pwdReset_account_tips').slideDown();
      return false;
    } else {
      $('.pwdReset_account_tips').slideUp();
      return true;
    }
  }

  // 判断新密码非空和是否符合要求（长度：8-20,数字、英文小写和大写字母）
  function valiDataPwdResetNewPwdNull() {
    var PwdResetPwdReg = /^[0-9A-Za-z]{8,20}$/;
    var PwdResetPassWord = $('.pwdReset_modal_newPassWord').val();
    if (PwdResetPassWord == '') {
      $('.pwdReset_newPassWord_tips').find('.pwdReset_tips_text').text('密码不能为空')
      $('.pwdReset_newPassWord_tips').slideDown();
      return false;
    } else if (!PwdResetPwdReg.test(PwdResetPassWord)) {
      $('.pwdReset_newPassWord_tips').find('.pwdReset_tips_text').text('需要8-20位字母、数字的组合，请重新输入')
      $('.pwdReset_newPassWord_tips').slideDown();
      return false;
    } else {
      $('.pwdReset_newPassWord_tips').slideUp();
      return true;
    }
  }

  // 判断再次输入的密码跟上面输入的新密码是否相符
  function valDataPwdResetNewPwdConfirmNull() {
    var PwdResetPassWord = $('.pwdReset_modal_newPassWord').val();
    var PwdResetPassWordConfirm = $('.pwdReset_modal_newPassWordConfirm').val();
    if (PwdResetPassWord != '' && PwdResetPassWordConfirm != '') {
      if (PwdResetPassWord != PwdResetPassWordConfirm) {
        $('.pwdReset_newPassWordConfirm_tips').find('.pwdReset_tips_text').text('两次密码输入不一样');
        $('.pwdReset_newPassWordConfirm_tips').slideDown();
        return false;
      } else {
        $('.pwdReset_newPassWordConfirm_tips').slideUp();
        return true;
      }
    }
  }

  // 失去焦点判断非空
  $('.pwdReset_modal_account').blur(function (e) {
    e.preventDefault();
    valiDataPwdResetAccountNull();
  });

  $('.pwdReset_modal_newPassWord').blur(function (e) {
    e.preventDefault();
    valiDataPwdResetNewPwdNull();
  });

  $('.pwdReset_modal_newPassWordConfirm').blur(function (e) {
    e.preventDefault();
    valDataPwdResetNewPwdConfirmNull();
  });

  // 密码重置模态框关闭时，清空错误提示
  $('#pwdResetModal').on('closed.zf.reveal', function () {
    $('#pwdResetSubmitId').attr('disabled', false);
    countdown = 0;
    settime($('#send_verificationCode'));
    $('.pwdReset_account_tips').hide();
    $('.pwdReset_verificationCode_tips').hide();
    $('.pwdReset_newPassWord_tips').hide();
    $('#newPassword_level').hide();
    $('.pwdReset_newPassWordConfirm_tips').hide();
  });

  // 密码强度验证
  $('.pwdReset_modal_newPassWord').keyup(function () {
    if ($(this).val() == '') {
      $('.newPassword_strength').slideUp();
      $('.newPassword_pw_weak').addClass('newPassword_pw_weak_active');
      $('.newPassword_pw_middle').removeClass('newPassword_pw_middle_active');
      $('.newPassword_pw_strong').removeClass('newPassword_pw_strong_active');
      $('.newPassword_pw_level_text').text('');
    } else {
      var strongRegex = new RegExp("^[0-9A-Za-z]{11,}$");
      var mediumRegex = new RegExp("^[0-9A-Za-z]{9,}$");
      var enoughRegex = new RegExp("^[0-9A-Za-z]{8,}$");

      if (false == enoughRegex.test($(this).val())) {
        //密码大于八位的时候，密码强度图片为灰色，强度最低
        $('.newPassword_strength').slideDown();
        $('.newPassword_pw_weak').addClass('newPassword_pw_weak_active');
        $('.newPassword_pw_middle').removeClass('newPassword_pw_middle_active');
        $('.newPassword_pw_strong').removeClass('newPassword_pw_strong_active');
        $('.newPassword_pw_level_text').text('低');
      } else if (strongRegex.test($(this).val())) {
        //密码大于十六位的时候，密码强度图片为最深色,强度最强
        $('.newPassword_pw_weak').addClass('newPassword_pw_weak_active');
        $('.newPassword_pw_middle').addClass('newPassword_pw_middle_active');
        $('.newPassword_pw_strong').addClass('newPassword_pw_strong_active');
        $('.newPassword_pw_level_text').text('高');
      } else if (mediumRegex.test($(this).val())) {
        //密码大于十二位的时候，密码强度图片为主题色，强度中等
        $('.newPassword_pw_middle').addClass('newPassword_pw_middle_active');
        $('.newPassword_pw_weak').addClass('newPassword_pw_weak_active');
        $('.newPassword_pw_strong').removeClass('newPassword_pw_strong_active');
        $('.newPassword_pw_level_text').text('中');
      }
      return true;
    }
  });

  //点击登录提交事件
  function loginValidate() {
    $.ajax({
      type: "POST",//方法类型
      dataType: "json",//预期服务器返回的数据类型
      url: "/index.php/home/Index/doLogin",//url
      data: $("#formId").serialize(),
      success: function (data) {
        if (data.code == 403) {
          $('.userName_tips').find('.tips_text').text('此用户已被禁用！');
          $('.userName_tips').slideDown();
          return false;
        } else if (data.code == 404) {
          $('.userName_tips').find('.tips_text').text('用户名不存在！');
          $('.userName_tips').slideDown();
          return false;
        } else if (data.code == 401) {
          $('.passWord_tips').find('.tips_text').text('密码错误！');
          $('.passWord_tips').slideDown();
          $('.login_modal_passWord').val('');
          return false;
        } else if (data.code == 200) {
          // console.log(data.data);
          $('#submitId').attr('disabled', 'disabled');
          $('#loginModal').foundation('close');
          $('.login_modal_userName').val('');
          $('.login_modal_passWord').val('');
          $('.login_register').css('display', 'none');
          // $('.data_downLoad_title').css('display', 'none');
          // $('.popup_loginModal_box').css('display', 'none');
          if (data.data['user_name'] != '' && data.data['user_name'] != null) {
            if (data.data['user_role'] == '6') {
              $('.regloginModel').html('')
              $('.regloginModel').append('<div class="login_complete">\n' +
                '<a href="javascript:void(0);" class="already_login">' + data.data['user_name'] + '</a>\n' +
                '<a href="/home/walk/article_editor" class="already_login" target="_blank">文章编辑</a>\n' +
                '<a class="login_out" href ="/home/index/loginOut">退出</a>\n' +
                '</div>');
            } else {
              $('.regloginModel').html('')
              $('.regloginModel').append('<div class="login_complete">\n' +
                '<a href="javascript:void(0);" class="already_login">' + data.data['user_name'] + '</a>\n' +
                '<a href="javascript:void(0);" class="already_login"></a>\n' +
                '<a class="login_out" href ="/home/index/loginOut">退出</a>\n' +
                '</div>');
            }
          } else {
            if (data.data['user_role'] == '6') {
              $('.regloginModel').html('');
              $('.regloginModel').append('<div class="login_complete">\n' +
                '<a href="javascript:void(0);" class="already_login">' + data.data['user_phone'] + '</a>\n' +
                '<a href="/walk/article_editor" class="already_login" target="_blank">文章编辑</a>\n' +
                '<a class="login_out" href ="/home/index/loginOut">退出</a>\n' +
                '</div>');
            } else {
              $('.regloginModel').html('')
              $('.regloginModel').append('<div class="login_complete">\n' +
                '<a href="javascript:void(0);" class="already_login">' + data.data['user_phone'] + '</a>\n' +
                '<a href="javascript:void(0);" class="already_login"></a>\n' +
                '<a class="login_out" href ="/home/index/loginOut">退出</a>\n' +
                '</div>');
            }
          }
          //直接刷新页面
          // location.reload();

        } else {
          return false;
        }
      }
    });
  }

  // 点击注册提交事件
  /* function registerValidate() {
    var registerPhoneValidate = /^[1][3,4,5,7,8][0-9]{9}$/;
    var registerEmailValidate = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var registerPhone = $('.registered_modal_phone').val();
    var registerEmail = $('.registered_modal_email').val();
    var reg = /^[0-9A-Za-z]{8,20}$/;
    var registerPassWord = $('.registered_modal_passWord').val();
    if ($('.registered_modal_phone').val() == '') {
      $('.registered_phone_tips').find('.registered_tips_text').text('手机号不能为空');
      $('.registered_phone_tips').slideDown();
      return false;
    } else if (!registerPhoneValidate.test(registerPhone)) {
      $('.registered_phone_tips').find('.registered_tips_text').text('请输入正确的手机号格式');
      $('.registered_phone_tips').slideDown();
      return false;
    } else if ($('.registered_modal_email').val() == '') {
      $('.registered_email_tips').find('.registered_tips_text').text('邮箱不能为空');
      $('.registered_email_tips').slideDown();
      return false;
    } else if (!registerEmailValidate.test(registerEmail)) {
      $('.registered_email_tips').find('.registered_tips_text').text('请输入正确的邮箱格式');
      $('.registered_email_tips').slideDown();
      return false;
    } else if ($('.registered_modal_passWord').val() == '') {
      $('.registered_passWord_tips').find('.registered_tips_text').text('密码不能为空')
      $('.registered_passWord_tips').slideDown();
      return false;
    } else if (!reg.test(registerPassWord)) {
      $('.registered_passWord_tips').find('.registered_tips_text').text('需要8-20位字母、数字的组合，请重新输入');
      $('.registered_passWord_tips').slideDown();
      return false;
    } else if ($('.registered_modal_passWordConfirm').val() == '') {
      $('.registered_passWordConfirm_tips').find('.registered_tips_text').text('确认密码不能为空')
      $('.registered_passWordConfirm_tips').slideDown();
      return false;
    } else {
      $.ajax({
        type: "POST", //方法类型
        dataType: "json", //预期服务器返回的数据类型
        url: "/index.php/home/Index/doRegister", //url
        data: $("#regFormId").serialize(),
        success: function (data) {
          console.log(data);
          if (data.code == 4031) {
            $('.registered_phone_tips').find('.registered_tips_text').text('该手机号已被注册！');
            $('.registered_phone_tips').slideDown();
            return false;
          } else if (data.code == 4032) {
            $('.registered_email_tips').find('.registered_tips_text').text('该邮箱已被注册！');
            $('.registered_email_tips').slideDown();
            return false;
          } else if (data.code == 4033) {
            $('.registered_passWordConfirm_tips').find('.registered_tips_text').text('两次密码输入不一致');
            $('.registered_passWordConfirm_tips').slideDown();
            return false;
          } else if (data.code == 200) {
            $('#regSubmitId').attr('disabled', 'disabled');
            $('#registeredModal').foundation('close');
            $('.registered_modal_phone').val('');
            $('.registered_modal_email').val('');
            $('#Password_level').css('display', 'none');
            $('.pw_weak').addClass('pw_weak_active');
            $('.pw_middle').removeClass('pw_middle_active');
            $('.pw_strong').removeClass('pw_strong_active');
            $('.pw_level_text').text('');
            $('.registered_modal_passWord').val('');
            $('.registered_modal_passWordConfirm').val('');
            alert('注册成功！')
          } else {
            return false;
          }
        }
      });
    }
  } */


  //点击发送验证码事件
  function sendIdentifyingCode() {
    var pwdResetPhone = /^[1][3,4,5,7,8][0-9]{9}$/;
    var pwdResetEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var pwdResetResult = $('.pwdReset_modal_account').val();
    if (pwdResetResult == '') {
      $('.pwdReset_account_tips').find('.pwdReset_tips_text').text('帐号不能为空');
      $('.pwdReset_account_tips').slideDown();
      return false;
    } else if (!pwdResetPhone.test(pwdResetResult) && !pwdResetEmail.test(pwdResetResult)) {
      $('.pwdReset_account_tips').find('.pwdReset_tips_text').text('请输入正确的帐号格式：手机号或邮箱');
      $('.pwdReset_account_tips').slideDown();
      return false;
    } else {
      $('.pwdReset_account_tips').slideUp();
      settime($('#send_verificationCode'));
      $.ajax({
        type: "POST",//方法类型
        dataType: "json",//预期服务器返回的数据类型
        url: "/index.php/home/Index/sendIdCode",//url
        data: $("#pwdFormId").serialize(),
        success: function (data) {
          if (data.code == 404) {
            countdown = 0;
            $('.pwdReset_account_tips').find('.pwdReset_tips_text').text('输入的账户不存在，请修改后重新发送');
            $('.pwdReset_account_tips').slideDown();
            return false;
          } else if (data.code == 200) {
            alert('验证码发送成功')
          }
        }
      });
      return true;
    }
  }


  //点击密码重置事件
  function resetPwd() {
    var pwdResetPhone = /^[1][3,4,5,7,8][0-9]{9}$/;
    var pwdResetEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var pwdResetResult = $('.pwdReset_modal_account').val();
    var reg = /^[0-9A-Za-z]{8,20}$/;
    var regPassWord = $('.pwdReset_modal_newPassWord').val();
    if ($('.pwdReset_modal_account').val() == '') {
      $('.pwdReset_account_tips').find('.pwdReset_tips_text').text('帐号不能为空');
      $('.pwdReset_account_tips').slideDown();
      return false;
    } else if (!pwdResetPhone.test(pwdResetResult) && !pwdResetEmail.test(pwdResetResult)) {
      $('.pwdReset_account_tips').find('.pwdReset_tips_text').text('请输入正确的帐号格式：手机号或邮箱')
      $('.pwdReset_account_tips').slideDown();
      return false;
    } else if ($('.pwdReset_modal_verificationCode').val() == '') {
      $('.pwdReset_verificationCode_tips').find('.pwdReset_tips_text').text('验证码不能为空')
      $('.pwdReset_verificationCode_tips').slideDown();
      return false;
    } else if ($('.pwdReset_modal_newPassWord').val() == '') {
      $('.pwdReset_newPassWord_tips').find('.pwdReset_tips_text').text('密码不能为空')
      $('.pwdReset_newPassWord_tips').slideDown();
      return false;
    } else if ($('.pwdReset_modal_newPassWord').val() != '' && !reg.test(regPassWord)) {
      $('.pwdReset_newPassWord_tips').find('.pwdReset_tips_text').text('需要8-20位字母、数字的组合，请重新输入');
      $('.pwdReset_newPassWord_tips').slideDown();
    } else if ($('.pwdReset_modal_newPassWordConfirm').val() == '') {
      $('.pwdReset_newPassWordConfirm_tips').find('.pwdReset_tips_text').text('确认密码不能为空')
      $('.pwdReset_newPassWordConfirm_tips').slideDown();
      return false;
    }
    $.ajax({
      type: "POST",//方法类型
      dataType: "json",//预期服务器返回的数据类型
      url: "/index.php/home/Index/forgetPwd",//url
      data: $("#pwdFormId").serialize(),
      success: function (data) {
        if (data.code == 404) {
          $('.pwdReset_account_tips').find('.pwdReset_tips_text').text('输入的账户不存在');
          $('.pwdReset_account_tips').slideDown();
        } else if (data.code == 401) {
          $('.pwdReset_verificationCode_tips').find('.pwdReset_tips_text').text('验证码错误');
          $('.pwdReset_verificationCode_tips').slideDown();
          return false;
        } else if (data.code == 403) {
          $('.pwdReset_newPassWordConfirm_tips').find('.pwdReset_tips_text').text('两次密码输入不一样');
          $('.pwdReset_newPassWordConfirm_tips').slideDown();
          return false;
        } else if (data.code == 200) {
          $('#pwdResetSubmitId').attr('disabled', 'disabled');
          $('#pwdResetModal').foundation('close');
          $('.pwdReset_modal_account').val('');
          $('.pwdReset_modal_verificationCode').val('');
          $('.pwdReset_modal_newPassWord').val('');
          $('.pwdReset_modal_newPassWordConfirm').val('');
          $('#newPassword_level').css('display', 'none');
          $('.newPassword_pw_weak').addClass('pw_weak_active');
          $('.newPassword_pw_middle').removeClass('pw_middle_active');
          $('.newPassword_pw_strong').removeClass('pw_strong_active');
          $('.newPassword_pw_level_text').text('');
          alert('密码重置成功！')
        } else {
          return false;
        }
      }
    })
  }

  //disabled
  function submitDisable(obj, sel) {
    obj.attr('disabled', sel);
  }

  //点击注册提交时的验证
  /* $('#regSubmitId').click(function (e) {
    e.preventDefault();
    if (!registeredFlag) {
      $('.registered_slide_tips').slideDown();
      return false;
    } else {
      $('.registered_slide_tips').slideUp();
    }
    registerValidate();
  }); */

  /*   //点击登录提交时的验证
  $('#loginModal').on('open.zf.reveal', function () {
    $('#submitId').on('click', function (e) {
      e.stopPropagation();
      var userNameValue = $('.login_modal_userName').val();
      var userPasswordValue = $('.login_modal_passWord').val();
      if (userNameValue == '') {
        $('.userName_tips').find('.tips_text').text('账号不能为空');
        $('.userName_tips').slideDown();
        return false;
      } else {
        $('.userName_tips').slideUp();
      }
      if (userPasswordValue == '') {
        $('.passWord_tips').find('.tips_text').text('密码不能为空');
        $('.passWord_tips').slideDown();
        return false;
      } else {
        $('.passWord_tips').slideUp();
      }
  
      if (!loginFlag) {
        $('.login_slide_tips').slideDown();
        return false;
      } else {
        $('.login_slide_tips').slideUp();
      }
      loginValidate();
    });
  });
  */

  //点击发送验证码时的验证
  $('#send_verificationCode').click(function (e) {
    console.log('send code');
    if ($('.pwdReset_modal_account').val() == '') {
      $('.pwdReset_account_tips').find('.pwdReset_tips_text').text('请输入帐号');
      $('.pwdReset_account_tips').slideDown();
      return false;
    } else {
      e.preventDefault();
      // sendIdentifyingCode();
      var result = sendIdentifyingCode();
      // settime($(this));
      submitDisable($('#send_verificationCode'), true);
    }
  });

  //点击密码重置事件
  $('#pwdResetSubmitId').click(function (e) {
    e.preventDefault();
    resetPwd();
  });

  // 初始化dropify头像上传
  $('.dropify_head_portrait').dropify({
    messages: {
      'default': '点击或拖拽替换头像',
      'replace': '点击或拖拽替换头像',
    },
  });

  // // 弹出设置模态框时，收回个人中心弹出框
  // $('#settingModal').on('open.zf.reveal', function () {
  //     $('.personal_center_info').stop(true, false).hide();
  // });

  // 编辑个人基本资料和详细信息
  $('#settingModal').on('open.zf.reveal', function () {
    $('.editor_btn').click(function (e) {
      e.preventDefault();
      $('.show_info').css('display', 'none');
      $('.modify_info').css('display', 'block');
    });

    // 编辑完个人基本信息后保存
    // 在这里提交用户修改完的表单信息到后台，后台返回信息后，重新渲染到show_info里面，再把show_info用display:block显示出来；
    $('.save_btn').click(function (e) {
      e.preventDefault();
      $('.show_info').css('display', 'block');
      $('.modify_info').css('display', 'none');
    });

    // 详细资料部分，同上
    $('.detailed_editor_btn').click(function (e) {
      e.preventDefault();
      $('.detailed_show_info').css('display', 'none');
      $('.detailed_modify_info').css('display', 'block');
    });

    $('.detailed_save_btn').click(function (e) {
      e.preventDefault();
      $('.detailed_show_info').css('display', 'block');
      $('.detailed_modify_info').css('display', 'none');
    })
  });
};

//（公用方法）ajax判断当前登录是否过期-ting-20200108
/* function checkLogin() {
  var flag = 1;
  $.ajax({
    type: "POST",
    url: "/home/index/checkLogin",
    data: {},
    async: false,//ajax执行完
    success: function (res) {
      // console.log(res);
      if (res.status == 0) {
        //登录操作
        // alert(res.message);
        $('#loginModal').foundation('open');//打开登录框
        flag = 0;
      }
    }
  })
  return flag;
} */