const cartRouters = {
  path: '/designIndex',
  component: () => import('@/views/design/layout'),
  name: 'designIndex',
  redirect: '/design1',
  meta: {
    title: '论坛',
    icon: 'el-icon-folder-opened'
  },
  children: [{
    path: '/design1',
    component: () => import('@/views/design/index/index'),
    name: 'design1',
    meta: {
      title: '设计首页',
      noCache: true
    }
  },
  {
    path: '/form',
    component: () => import('@/views/design/form/layout'),
    name: 'form',
    meta: {
      title: '装修表单',
      noCache: true
    },
    children: [
      {
        path: '/form/cart',
        component: () => import('@/views/design/form/cart/index'),
        name: 'cart',
        meta: {
          title: '装修清单',
          noCache: true
        }
      },
      {
        path: '/form/quote',
        component: () => import('@/views/design/form/quote/index'),
        name: 'quote',
        meta: {
          title: '清单报价',
          noCache: true
        }
      },
      {
        path: '/form/createPlan',
        // component: () => import('@/views/design/form/plan/index'),
        component: () => import('@/views/design/form/appointment/index'),
        name: 'plan',
        meta: {
          title: '生成方案',
          noCache: true
        }
      }
    ]
  }
  ]
}

export default cartRouters
