const bbsRouters = {
  path: '/bbsIndex',
  component: () => import('@/views/bbs/layout'),
  name: 'bbs',
  redirect: '/bbs',
  meta: {
    title: '论坛',
    icon: 'el-icon-folder-opened'
  },
  children: [{
    path: '/bbs',
    component: () => import('@/views/bbs/index/index'),
    name: 'bbsIndex',
    meta: {
      title: '论坛首页',
      noCache: true
    }
  },
  {
    path: '/bbs/article',
    component: () => import('@/views/bbs/article/index'),
    name: 'articleDetails',
    meta: {
      title: '文章详情',
      noCache: true
    }
  },
  {
    path: '/bbs/topicList',
    component: () => import('@/views/bbs/topic/list'),
    name: 'topic',
    meta: {
      title: '话题列表',
      noCache: true
    }
  },
  {
    path: '/bbs/topic',
    component: () => import('@/views/bbs/topic/details'),
    name: 'topic',
    meta: {
      title: '话题详情',
      noCache: true
    }
  },
  {
    path: '/bbs/publish',
    component: () => import('@/views/bbs/publish/index'),
    name: 'topic',
    meta: {
      title: '发布问题',
      noCache: true
    }
  },
  {
    path: '/bbs/answerList',
    component: () => import('@/views/bbs/answer/list'),
    name: 'topic',
    meta: {
      title: '问题列表',
      noCache: true
    }
  },
  {
    path: '/bbs/answerWrite',
    component: () => import('@/views/bbs/answer/details'),
    name: 'topic',
    meta: {
      title: '回答问题',
      noCache: true
    }
  },
  {
    path: '/bbs/searchResult',
    component: () => import('@/views/bbs/searchResult/index'),
    name: 'topic',
    meta: {
      title: '搜索结果',
      noCache: true
    }
  },

  {
    path: '/video',
    component: () => import('@/views/bbs/article/video'),
    name: 'video',
    meta: {
      title: '视频',
      noCache: true
    }
  },
  {
    path: '/coursePost',
    component: () => import('@/views/bbs/components/courseItem/coursePost'),
    name: 'coursePost',
    meta: {
      title: '发表帖子',
      noCache: false
    }
  },
  ]
}

export default bbsRouters
