import request from '@/utils/request'

const key = "home";

// seo设置列表
export function tdkList (params) {
  return request({
    url: `/pc/seoSetting`,
    method: 'get',
    params
  })
}


// 广告轮播图
export function fetchAdList (params) {
  return request({
    url: `/pc/common/ad`,
    method: 'get',
    params
  })
}


// 广告轮播图详情
export function fetchAdDetail (params, id) {
  return request({
    url: `/pc/common/ad/${id}`,
    method: 'get',
    params
  })
}

// 瓷砖分类（轮播） 产品参数列表 
export function fetchProductList (params) {
  return request({
    url: `/pc/product_param/list`,
    method: 'get',
    params
  })
}

// 首页新闻动态列表
export function fetchNewsList (params) {
  return request({
    url: `/pc/index/news`,
    method: 'get',
    params: {
      ...params,
    }
  })
}

// 门店 - 列表
export function seachStoreList (params) {
  params.brand_id = 1; // 金舵
  return request({
    url: `/pc/store`,
    method: 'get',
    params
  })
}

// 发送短信
export function sendShortMessage (params) {
  return request({
    url: `/pc/message/send`,
    method: 'get',
    params
  })
}


// 参数-列表
export function parameterList (data) {
  return request({
    baseURL: process.env.VUE_APP_PRODUCT_API,
    url: `/open/api/parameter/list`,
    method: 'post',
    data
  })
}

// 参数值-列表
export function parameterOption (data) {
  return request({
    baseURL: process.env.VUE_APP_PRODUCT_API,
    url: `/open/api/parameter_option/listByAccessoryProduct`,
    method: 'post',
    data
  })
}

// 产品配套-列表状态，排序，图片官网数据
export function productOption (params) {
  return request({
    url: `/pc/accessory/product/list`,
    method: 'get',
    params
  })
}

// 产品配套-列表
export function productList (data) {
  return request({
    baseURL: process.env.VUE_APP_PRODUCT_API,
    url: `/open/api/accessory/product/list`,
    method: 'post',
    data
  })
}

// 产品配套-详情
export function productDetail (params) {
  return request({
    url: `/pc/accessory/product/detail`,
    method: 'get',
    params
  })
}

