const designerCenterRouters = {
  path: '/designerCenter',
  component: () => import('@/views/designerCenter'),
  name: 'designerCenter',
  redirect: '/designerCenter/myScheme/list',
  meta: {
    title: '个人中心',
  },
  children: [
    {
      path: 'myScheme',
      component: () => import('@/components/layout/Nested'),
      name: 'myScheme',
      redirect: 'myScheme/list',
      meta: {
        title: '个人方案',
        noCache: false
      },
      children: [
        {
          path: 'list',
          component: () => import('@/views/designerCenter/myScheme/list'),
          name: 'mySchemes',
          meta: {
            title: '我的方案',
            noCache: false,
          },
        }, 
        {
          path: 'recycle',
          component: () => import('@/views/designerCenter/myScheme/recycle'),
          name: 'Recycle',
          meta: {
            title: '回收站',
            noCache: false,
          },
        },              
        {
          path: 'detail/:id',
          component: () => import('@/views/designerCenter/myScheme/detail'),
          name: 'mySchemeInfo',
          meta: {
            title: '详情',
            activeTitle: '我的方案',
            noCache: false
          },
        }
      ]
    },
    {
      path: 'myAlbum',
      component: () => import('@/components/layout/Nested'),
      name: 'myAlbum',
      redirect: 'myAlbum/list',
      meta: {
        title: '作品管理',
        noCache: false
      },
      children: [
        {
          path: 'list',
          component: () => import('@/views/designerCenter/myAlbum/list'),
          name: 'myAlbums',
          meta: {
            title: '我的作品',
            noCache: false
          },
        },
        // {
        //   path: 'detail/:id',
        //   component: () => import('@/views/designerCenter/myAlbum/detail'),
        //   name: 'myAlbumInfo',
        //   meta: {
        //     title: '详情',
        //     noCache: false
        //   },
        // },
        {
          path: 'add',
          component: () => import('@/views/designerCenter/myAlbum/form'),
          name: 'addMyScheme',
          meta: {
            title: '上传作品',
            noCache: false
          },
        },
      ]
    },
    {
      path: 'clientScheme',
      component: () => import('@/components/layout/Nested'),
      name: 'clientScheme',
      redirect: 'clientScheme/list',
      meta: {
        title: '顾客方案',
        noCache: false
      },
      children: [
        {
          path: 'list',
          component: () => import('@/views/designerCenter/clientScheme/list'),
          name: 'clientSchemes',
          meta: {
            title: '列表',
            activeTitle: '顾客方案',
            noCache: false
          },
        },
        // {
        //   path: 'detail/:id',
        //   component: () => import('@/views/designerCenter/clientScheme/detail'),
        //   name: 'clientSchemeInfo',
        //   meta: {
        //     title: '详情',
        //     noCache: false
        //   },
        // },
        {
          path: 'add',
          component: () => import('@/views/designerCenter/clientScheme/form'),
          name: 'addMyScheme',
          meta: {
            title: '新建',
            activeTitle: '顾客方案',
            noCache: false
          },
        },
        {
          path: 'edit/:id',
          component: () => import('@/views/designerCenter/clientScheme/form'),
          name: 'editMyScheme',
          meta: {
            title: '编辑',
            activeTitle: '顾客方案',
            noCache: false
          },
        },
      ]
    },
    {
      path: 'reservation',
      component: () => import('@/components/layout/Nested'),
      name: 'reservation',
      redirect: 'reservation/list',
      meta: {
        title: '顾客预约单',
        noCache: false
      },
      children: [
        {
          path: 'list',
          component: () => import('@/views/designerCenter/reservation/list'),
          name: 'reservations',
          meta: {
            title: '列表',
            activeTitle: '顾客预约单',
            noCache: false
          },
        },
        {
          path: 'detail/:id',
          component: () => import('@/views/designerCenter/reservation/detail'),
          name: 'reservationInfo',
          meta: {
            title: '详情',
            activeTitle: '顾客预约单',
            noCache: false
          },
        }
      ]
    },
    {
      path: 'recommend',
      component: () => import('@/components/layout/Nested'),
      name: 'recommend',
      redirect: 'recommend/list',
      meta: {
        title: '推荐单',
        noCache: false
      },
      children: [
        {
          path: 'list',
          component: () => import('@/views/designerCenter/recommend/list'),
          name: 'recommends',
          meta: {
            title: '列表',
            activeTitle: '推荐单',
            noCache: false
          },
        },
        {
          path: 'detail/:id',
          component: () => import('@/views/designerCenter/recommend/detail'),
          name: 'recommendInfo',
          meta: {
            title: '详情',
            activeTitle: '推荐单',
            noCache: false
          },
        }
      ]
    },
    {
      path: 'settings',
      component: () => import('@/components/layout/Nested'),
      name: 'designerSettings',
      redirect: 'settings/info',
      meta: {
        title: '账号设置',
        noCache: false
      },
      children: [
        {
          path: 'info',
          component: () => import('@/views/designerCenter/settings/info'),
          name: 'designerInfoSetting',
          meta: {
            title: '个人资料',
            noCache: false
          },
        },
        {
          path: 'password',
          component: () => import('@/views/designerCenter/settings/password'),
          name: 'designerPasswordSetting',
          meta: {
            title: '修改密码',
            noCache: false
          },
        },
      ]
    },
    {
      path: 'myPoints',
      component: () => import('@/components/layout/Nested'),
      name: 'myPoints',
      redirect: 'myPoints/list',
      meta: {
        title: '积分管理',
        noCache: false
      },
      children: [
        {
          path: 'list',
          component: () => import('@/views/designerCenter/myPoints/list'),
          name: 'myPointsList',
          meta: {
            title: '我的积分',
            noCache: false
          },
        },
        {
          path: 'rules',
          component: () => import('@/views/designerCenter/myPoints/rules'),
          name: 'pointsRules',
          meta: {
            title: '积分规则',
            activeTitle: '我的积分',
            noCache: false
          },
          
        },
      ]
    },
    {
      path: 'invest',
      component: () => import('@/components/layout/Nested'),
      name: 'invest',
      redirect: 'invest/create',
      meta: {
        title: '充值管理',
        noCache: false
      },
      children: [
        {
          path: 'create',
          component: () => import('@/views/designerCenter/invest/create'),
          name: 'investVIP',
          meta: {
            title: 'VIP充值',
            noCache: false
          }      
        },
        {
          path: 'list',
          component: () => import('@/views/designerCenter/invest/list'),
          name: 'investList',
          meta: {
            title: '充值记录',
            noCache: false
          }      
        }        
      ]
    },
  ]
}

export default designerCenterRouters
