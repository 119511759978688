
/**
 * @author:刘志江/zj
 * @date:2020-10-12
 * @descrtion:首页模块路由
 */

const homeRoutes = {
  path: "home",
  name: "Home",
  meta:{id:1}, 
  component: () =>
  import("@/views/home/index.vue"),
};

export default homeRoutes