export function nav() {
  return fnc()
}

function fnc() {
      
      // foundation初始化
      $('body').foundation();
      // 导航栏主页hover
      $('.list_item').hover(function () {
          
          $(this).addClass('active');
      }, function () {
          $(this).removeClass('active');
      })
  
      // 导航栏产品中心hover
      $('.product_center_item').hover(function () {
          $('.dropDown_product_center').stop(true, false).slideDown(500, function () {
              $(this).css('height', 'auto');
          });
      }, function () {
          $('.dropDown_product_center').stop(true, false).slideUp(500);
      })
      $('.dropDown_product_center').mouseenter(function () {
          $(this).stop(true, false).slideDown(500, function () {
              $(this).css('height', 'auto');
          });
          $('.product_center_item').addClass('active');
  
      });
      $('.dropDown_product_center').mouseleave(function () {
          $(this).stop(true, false).slideUp(500, function () {
              $('.more_show').hide();
              $('.animation_navArrow_up').show();
          });
          $('.product_center_item').removeClass('active');
      });
  
      //导航栏装修社区hover
      $('.decorate_community_item').hover(function () {
          $('.dropDown_decorate_community').stop(true, false).slideDown(500);
      }, function () {
          $('.dropDown_decorate_community').stop(true, false).slideUp(500);
      });
      $('.dropDown_decorate_community').mouseenter(function () {
          $(this).stop(true, false).slideDown();
          $('.decorate_community_item').addClass('active');
      });
      $('.dropDown_decorate_community').mouseleave(function () {
          $(this).stop(true, false).slideUp();
          $('.decorate_community_item').removeClass('active');
      });
  
      //导航栏经销门店hover
      $('.offline_store_item').hover(function () {
          $('.dropDown_offline_store').stop(true, false).slideDown(500);
      }, function () {
          $('.dropDown_offline_store').stop(true, false).slideUp(500);
      });
      $('.dropDown_offline_store').mouseenter(function () {
          $(this).stop(true, false).slideDown();
          $('.offline_store_item').addClass('active');
      });
      $('.dropDown_offline_store').mouseleave(function () {
          $(this).stop(true, false).slideUp();
          $('.offline_store_item').removeClass('active');
      });
  
      //导航栏1对1设计hover
      $('.one_to_one_design_item').hover(function () {
          $('.one_to_one_design').stop(true, false).slideDown(500);
      }, function () {
          $('.one_to_one_design').stop(true, false).slideUp(500);
      });
      $('.one_to_one_design').mouseenter(function () {
          $(this).stop(true, false).slideDown();
          $('.one_to_one_design_item').addClass('active');
      });
      $('.one_to_one_design').mouseleave(function () {
          $(this).stop(true, false).slideUp();
          $('.one_to_one_design_item').removeClass('active');
      });
  
      // 二级nav 设计支持
      $('.one_to_one_design_item').hover(function () {
          $('.free_design_sub').stop(true, false).slideDown(500);
      }, function () {
          $('.free_design_sub').stop(true, false).slideUp(500);
      });
      $('.free_design_sub').mouseenter(function () {
          $(this).stop(true, false).slideDown();
          $('.free_design_sub_listItem').addClass('active');
      });
      $('.free_design_sub').mouseleave(function () {
          $(this).stop(true, false).slideUp();
          $('.free_design_sub_listItem').removeClass('active');
      });
  
      // 二级nav 服务支持
      $('.service_support_item').hover(function () {
          $('.service_support').stop(true, false).slideDown(500);
      }, function () {
          $('.service_support').stop(true, false).slideUp(500);
      });
      $('.service_support').mouseenter(function () {
          $(this).stop(true, false).slideDown();
          $('.service_support_item').addClass('active');
      });
      $('.service_support').mouseleave(function () {
          $(this).stop(true, false).slideUp();
          $('.service_support_item').removeClass('active');
      });
  
      // 二级nav 走进金舵
      $('.walk_into_jinduo_item').hover(function () {
          $('.walk_into_jinduo_nav').stop(true, false).slideDown(500);
      }, function () {
          $('.walk_into_jinduo_nav').stop(true, false).slideUp(500);
      });
      $('.walk_into_jinduo_nav').mouseenter(function () {
          $(this).stop(true, false).slideDown();
          $('.walk_into_jinduo_item').addClass('active');
      });
      $('.walk_into_jinduo_nav').mouseleave(function () {
          $(this).stop(true, false).slideUp();
          $('.walk_into_jinduo_item').removeClass('active');
      });
  
      // 二级nav 联系我们
      $('.contact_us_item').hover(function () {
          $('.contact_us_nav').stop(true, false).slideDown(500);
      }, function () {
          $('.contact_us_nav').stop(true, false).slideUp(500);
      });
      $('.contact_us_nav').mouseenter(function () {
          $(this).stop(true, false).slideDown();
          $('.contact_us_item').addClass('active');
      });
      $('.contact_us_nav').mouseleave(function () {
          $(this).stop(true, false).slideUp();
          $('.contact_us_item').removeClass('active');
      });
  
      // 点击箭头加载更多公共配件
      $('.animation_navArrow_up').on('click', function () {
          $('.more_show').stop(true, false).slideDown(500);
          $(this).hide();
      })
  
      //处理加载页面时的导航栏对应当前页面的高亮
      var link_active = $('#nav_link_active').val();
      var str = link_active + '_link_active';
      $('#' + str).addClass('link_active');//定位到nav做高亮
  
  
      // 点击登录弹出提示模态框
      $(document).on('click', '.decoration_list_nav', function () {
          openGlobalModal('#216290', '登录后才可使用加入装修清单、智能搭配方案、问答、晒家、评论等相关功能。');
          $('#Global_Tip_Modal').foundation('open');
          $('#Global_Tip_Modal').find('.config_btn').on('click', function () {
              $('#loginModal').foundation('open');
          })
      })
  
      // 点击登录弹出提示模态框
      $(document).on('click', '.personal_center_nav', function () {
          openGlobalModal('#216290', '登录后才可使用加入装修清单、智能搭配方案、问答、晒家、评论等相关功能。');
          $('#Global_Tip_Modal').foundation('open');
          $('#Global_Tip_Modal').find('.config_btn').on('click', function () {
              $('#loginModal').foundation('open');
          })
      })
  
      // // 封装全局模态框
      function openGlobalModal(iconCol, text) {
          $('#Global_Tip_Modal').on('open.zf.reveal', function () {
              $('#Global_Tip_Modal').find('.iconfont').addClass('icon-success').css('color', iconCol);
              $('#Global_Tip_Modal').find('.open_tip').css('width', '80%');
              $('#Global_Tip_Modal').find('.tip_text').html(text);
          })
      }

  
  //获取url中的某个参数-chentingbo
  function getQueryVariable(variable) {//获取参数id
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split("=");
          if (pair[0] == variable) { return pair[1]; }
      }
      return (false);
  }
}
