/*
 * @Descripttion:
 * @version:
 * @Author: gjqiang
 * @Date: 2020-07-27 17:41:35
 */
// 正确码
export const RES_SUCCESS_CODE = 0

export const API_PRIFIX = '/pc'

// 图片前缀
export const IMAGE_PREFIX = process.env.VUE_APP_RESOURCE_HOST

// 图片上传服务器域名
export const UPLOAD_URL_HOST = process.env.VUE_APP_UPLOAD_HOST

// 图片接口
// export const RESOURCE_API_URL = UPLOAD_URL_HOST + '/index.php'
export const RESOURCE_API_URL = UPLOAD_URL_HOST

// 图片上传服务器地址
export const UPLOAD_URL = RESOURCE_API_URL + '/Upload/img_web_upload'

// 静态资源数据(前端固定的静态图片)
export const STATIC_RESOURCE_HOST = 'https://resource.jinduo.com'

console.log(process.env.VUE_APP_RESOURCE_HOST, process.env.VUE_APP_UPLOAD_HOST)

