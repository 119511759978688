<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

import { mapState } from "vuex";

// import {
//   getTDK,
//   setTDK,
//   removeTDK
// } from '@/utils/auth'

export default {
  components: {
  },

  created() {
    // this.getListByTDK();

    let token = this.$cookies.get("Admin-Token") || "";
    if (token) {
      this.$store.dispatch("user/getInfo");
    }
    if (sessionStorage.getItem("path")) {
      this.$store.commit(
        "user/SET_PATH",
        JSON.parse(sessionStorage.getItem("path"))
      );
    }
    if (sessionStorage.getItem("keyword")) {
      this.$store.commit(
        "user/CHANGE_KEYWORD",
        sessionStorage.getItem("keyword")
      );
    }

    this.$store.dispatch('params/setParams');
    this.$store.dispatch('params/setAliveParams');
    this.$store.dispatch('params/setBrands');
    
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("path", JSON.stringify(this.path));
      sessionStorage.setItem("keyword", this.keyword);
    });
  },

  watch: {
    $route(n) {
      if (n.name == "articleDetails") {
        document.body.setAttribute(
          "style",
          "background-color: #f0f0f0 !important"
        );
      } else {
        document.body.setAttribute("style", "background-color: white");
      }
    },
  },
  computed: {
    ...mapState("user", ["path", "keyword"]),
  },

  methods: {
      //  async getListByTDK(){  
      //     try{
      //       const params = {
      //         page : 1,
      //         page_size: 9999
      //       }
      //       const res = await tdkList(params)

      //       const list = res.data.data;
      //       let map ={};
      //       for (let i = 0; i < list.length; i++) {
      //         map[list[i].id] = list[i]  
      //       }
      //       console.log("getListByTDK map",map);
      //       localStorage.setItem(`tdkList`, JSON.stringify(map));
      //       console.log("tdkList map",JSON.stringify(map));

      //       // setTDK(JSON.stringify(map));
      //       // console.log("tdk after",getTDK());
      //         }catch(error){
      //         console.log(error)
      //       }
      //     }      

  },
};
</script>

<style lang="less">
@import "./styles/foundation.min.css";
@import "./styles/reset.css";

* {
  outline: none;
}
</style>
