
/**
 * @author:刘志江/zj
 * @date:2020-10-13
 * @descrtion:个人中心模块路由
 */

const personalRoutes = {
  path: 'personal',
  name: 'personalCenter',
  component: () => import('@/views/personalCenter/index.vue'),
  redirect: '/personal/me',
  children: [
    // 个人中心首页
    {
      path: 'me',
      name: 'Me',
      component: () => import('@/views/personalCenter/me.vue')
    },
    // 个人中心设置
    {
      path: 'setting',
      name: 'Setting',
      component: () => import('@/views/personalCenter/setting.vue')
    },
    // 个人中心消息
    {
      path: 'message',
      name: 'message',
      component: () => import('@/views/personalCenter/message.vue')
    },
    //  // 个人中心智能搭配方案
    {
      path: 'smart',
      name: 'Smart',
      component: () => import('@/views/personalCenter/smartSolution')
    },
    {
      path: 'wait/:id',
      name: 'Wait',
      component: () => import('@/views/personalCenter/wait')
    },
    // 预约设计方案
    {
      path: 'free',
      name: 'Free',
      component: () => import('@/views/personalCenter/freeDesign')
    },
    //  预约设计
    {
      path: 'reserved/:id',
      name: 'Reserved',
      component: () => import('@/views/personalCenter/reserved')
    },
    // 我的收藏
    {
      path: 'favorites',
      name: 'Favorites',
      component: () => import('@/views/personalCenter/favorites')
    },
    // 我的发布
    {
      path: 'release/:id',
      name: 'Release',
      component: () => import('@/views/personalCenter/release')
    },
    // 关注设计师
    {
      path: 'focusDesigners',
      name: 'FocusDesigners',
      component: () => import('@/views/personalCenter/focusDesigners')
    }
  ]

}

export default personalRoutes
