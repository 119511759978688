/**
 * @author:刘志江/zj
 * @date:2020-10-16
 * @descrtion:产品模块路由
 */
const downloadRoutes = {
  path: 'download',
  name: 'Download',
  component: () => import('@/views/download/index')
}

export default downloadRoutes

