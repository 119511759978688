import request from '@/utils/request'

/**
 * @name: 获取地区列表
 * @param {init} region_parent_id 上级
 * @param {init} region_type 类型 1省 2市 3区
 * @return {type}
 */
export function fetchRegionsList(params) {
  return request({
    url: '/api/region/list',
    method: 'get',
    params: {
      ...params,
      page_size: 9999
    }
  })
}

export function getRegions(params) {
  return request({
    url: '/api/region/list',
    method: 'get',
    params
  })
}

export function fetchStoreList(params) {
  return request({
    url: '/pc/store',
    method: 'get',
    params: {
      ...params,
      page_size: 9999
    }
  })
}

export function fetchAdList(params) {
  return request({
    url: '/pc/common/ad',
    method: 'get',
    params: {
      ...params,
      page_size: 9999
    }
  })
}
