const designRouters = {
  path: '/design',
  component: () => import('@/components/layout/Nested'),
  name: 'design',
  redirect: '/albums/0-0-0-0-0-0-0-0/',
  meta: {
    title: '设计支持',
    icon: 'el-icon-folder-opened'
  },
  children: [
    {
      path: '/albums/:query',
      component: () => import('@/views/album/list'),
      name: 'albums',
      meta: {
        title: '设计案例',
        noCache: true,
        id:8
      }
    },
    // {
    //   path: '/album/:id',
    //   redirect: '/album/:id.html',
    // },
    {
      path: '/album/:id.html',
      component: () => import('@/views/album/detail'),
      name: 'album',
      meta: {
        title: '案例详情',
        noCache: true
      }
    },
    {
      path: '/designers/:query',
      component: () => import('@/views/designer/list'),
      name: 'designers',
      meta: {
        title: '找设计师',
        noCache: true,
        id:7
      }
    },
    // {
    //   path: '/designer/:id',
    //   redirect: '/designer/:id.html',
    // },
    {
      path: '/designer/:id.html',
      component: () => import('@/views/designer/detail'),
      name: 'designer',
      meta: {
        title: '设计师主页',
        noCache: true
      }
    },
    // {
    //   path: '/storeIndex/:id',
    //   redirect: '/storeIndex/:id.html',
    // },
    {
      path: '/storeIndex/:id.html',
      component: () => import('@/views/store/detail'),
      name: 'storeDetail',
      meta: {
        title: '门店主页',
        noCache: true
      }
    }
  ]
}

export default designRouters
