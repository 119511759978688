<template>
  <div>
    <el-dialog
      class="tips"
      :close-on-click-modal="false"
      append-to-body
      :visible.sync="showConfirmModal"
      :show-close="false"
      width="355px"
    >
      <div align="right" slot="title" @click="closeAll">
        <i class="cus-close el-icon el-icon-close"></i>
      </div>
      <div class="modal-confirm" v-show="showConfirmModal">
        <span
          >登录后才可使用加入装修清单、智能搭配方案、问答、晒家、评论等相关功能。</span
        >
        <button @click="onConfirm">确定</button>
      </div>
    </el-dialog>

    <!--登录-->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="showLoginForm"
      lock-scroll
      :show-close="false"
      width="355px"
    >
      <div align="right" slot="title" @click="closeAll">
        <i class="cus-close el-icon el-icon-close"></i>
      </div>
      <div class="modal-login" v-show="showLoginForm">
        <!-- <span class="btn-close" @click="closeLoginForm">x</span> -->

        <div class="input-item login_modal_title_text">账号登录</div>
        <div class="input-item">
          <el-input
            v-model="postForm.username"
            placeholder="手机/邮箱/用户名"
          ></el-input>
          <div class="text_class" @click="codeClick(true)" v-if="!codeShowFlag">
            验证码登录
          </div>
          <div class="text_class" @click="codeClick(false)" v-if="codeShowFlag">
            密码登录
          </div>
        </div>

        <div v-if="!codeShowFlag" class="input-item">
          <el-input
            type="password"
            v-model="postForm.password"
            placeholder="8-20位字母、数字的组合"
          ></el-input>
        </div>

        <div v-if="codeShowFlag" class="logincode">
          <el-input placeholder="验证码" v-model="postForm.code" type="text"></el-input>
          <div
            class="btn"
            :class="{ disabled: smsDisabled }"
            @click="getSms('login')"
          >
            {{ smsText }}
          </div>
        </div>

        <div class="input-item">
          <JcRange status="status" :successFun="handleMoveSlider"></JcRange>
          <!-- <span>请移动滑块到最右侧</span> -->
        </div>
        <div class="input-item small-text">
          <span class="f-l">
            <el-checkbox v-model="isAutoLogin"></el-checkbox>
            <span style="margin-left: 10px">自动登录</span>
          </span>
          <span
            class="f-r"
            @click="
              showLoginForm = false;
              showFindPsw = true;
            "
            >忘记密码</span
          >
        </div>
        <div class="input-item">
          <button class="submit-btn" @click="handleLogin">账号登录</button>
        </div>
        <div class="input-item small-text">
          <span class="f-r" @click="openRegister">没有帐号？马上注册</span>
        </div>
      </div>
    </el-dialog>

    <!--注册-->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showRegister"
      :show-close="false"
      width="355px"
    >
      <div align="right" slot="title" @click="closeAll">
        <i class="cus-close el-icon el-icon-close"></i>
      </div>
      <div class="register-form">
        <div class="register-title">
          <div class="input-item login_modal_title_text">账号注册</div>
          <span
            @click="
              showRegister = false;
              showLoginForm = true;
            "
            >已有账号，马上登录</span
          >
        </div>
        <el-form
          label-width="0px"
          ref="registerForm"
          :model="form"
          :rules="signinRules"
        >
          <el-form-item label="" prop="user_phone">
            <el-input placeholder="手机号" v-model="form.user_phone"></el-input>
          </el-form-item>
          <el-form-item label="" prop="user_email">
            <el-input placeholder="邮箱" v-model="form.user_email"></el-input>
          </el-form-item>

          <el-form-item label="" prop="user_pwd">
            <el-input
              placeholder="密码"
              show-password
              v-model="form.user_pwd"
            ></el-input>
          </el-form-item>

          <el-form-item label="" prop="user_pwd_confirmation">
            <el-input
              placeholder="再次输入密码"
              show-password
              v-model="form.user_pwd_confirmation"
            ></el-input>
          </el-form-item>

          <el-form-item label="">
            <JcRange status="status" :success-fun="successRange"></JcRange>
            <span v-show="showErr" class="el-form-item__error"
              >请进行滑动验证</span
            >
          </el-form-item>

          <el-form-item label="" prop="argee">
            <el-checkbox v-model="form.argee"></el-checkbox> 同意
          </el-form-item>

          <el-button class="custom-button" @click="postRegister('registerForm')"
            >注册</el-button
          >
        </el-form>
      </div>
    </el-dialog>
    <!--重置密码-->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showFindPsw"
      :show-close="false"
      width="355px"
    >
      <div align="right" slot="title" @click="closeAll">
        <i class="cus-close el-icon el-icon-close"></i>
      </div>
      <div class="register-form">
        <div class="register-title">
          <div class="input-item login_modal_title_text">重置密码</div>
          <span
            @click="
              showFindPsw = false;
              showRegister = true;
            "
            >忘记账号，重新注册</span
          >
        </div>
        <el-form label-width="0px" ref="find" :model="form" :rules="findRules">
          <el-form-item label="" prop="account">
            <el-input placeholder="账号" v-model="form.account"></el-input>
          </el-form-item>
          <el-form-item label="" prop="code">
            <div class="code">
              <el-input placeholder="验证码" v-model="form.code"></el-input>
              <div
                class="btn"
                :class="{ disabled: smsDisabled }"
                @click="getSms('update')"
              >
                {{ smsText }}
              </div>
            </div>
          </el-form-item>
          <el-form-item label="" prop="user_pwd">
            <el-input
              placeholder="新密码"
              show-password
              v-model="form.user_pwd"
            ></el-input>
            <p
              style="
                font-size: 12px;
                text-align: left;
                margin: 5px 0 0px;
                line-height: 1.5;
                letter-spacing: 1px;
              "
            >
              必须包含数字,英文,特殊符号[!@#$%^&*(),], 长度8-20位
            </p>
          </el-form-item>
          <el-form-item label="" prop="user_pwd_confirmation">
            <el-input
              placeholder="再次输入密码"
              show-password
              v-model="form.user_pwd_confirmation"
            ></el-input>
          </el-form-item>

          <el-button class="custom-button" @click="onFindPsw('find')"
            >重置</el-button
          >
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import JcRange from "@/components/slide";
import { register, findPsw, getSmsCode, login, getInfo } from "@/api/user.js";
export default {
  name: "Login",
  data() {
    const phone = (rule, value, callback) => {
      const reg = /^[0-9A-Za-z]{8,20}$/;
      if (!value) {
        callback("手机号不能为空");
      } else if (!reg.test(value)) {
        callback("手机号不正确");
      } else {
        callback();
      }
    };

    const email = (rule, value, callback) => {
      // callback(); // TEST_ONLY
      const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (!value) {
        callback("邮箱不能为空");
      } else if (!reg.test(value)) {
        callback("邮箱格式不正确");
      } else {
        callback();
      }
    };

    const pass = (rule, value, callback) => {
      // callback(); // TEST_ONLY

      // var reg = /^[0-9A-Za-z]{8,20}$/;
      // if (!value) {
      //   callback("密码不能为空");
      // } else if (!reg.test(value)) {
      //   callback("密码格式不正确，长度：8-20,数字、英文小写和大写字母");
      // } else {
      //   callback();
      // }

      if (!/[0-9]+/.test(value)) {
        return callback(new Error("密码必须包含数字或字母"));
      }
      if (!/[A-Za-z]+/.test(value)) {
        return callback(new Error("密码必须包含数字或字母"));
      }
      if (!/[!@#$%^&*(),]+/.test(value)) {
        return callback(new Error("密码必须包含特殊符号!@#$%^&*(),"));
      }
      if (value.length < 6) {
        callback(new Error("密码不能少于6位"));
      } else if (value.length > 20) {
        callback(new Error("密码不能超过20位"));
      } else {
        if (this.form.user_pwd_confirmation !== "") {
          // this.$refs.find.validateField('user_pwd_confirmation') // Cannot read properties of undefined (reading 'validateField')"
          this.$refs.registerForm.validateField("user_pwd_confirmation");
        }
        callback();
      }
    };

    const passConfirm = (rule, value, callback) => {
      if (!value) {
        callback("请再次输入密码");
      } else if (value !== this.form.user_pwd && this.form.user_pwd !== "") {
        callback("两次输入的密码不相同！");
      } else {
        callback();
      }
    };

    const argee = (rule, value, callback) => {
      if (!value) {
        callback("请阅读并选择同意协议");
      } else {
        callback();
      }
    };

    const range = (rule, value, callback) => {
      if (!this.disabled) {
        callback("请阅读并选择同意协议");
      } else {
        callback();
      }
    };

    const account = (rule, value, callback) => {
      const reg = /^[0-9A-Za-z]{8,20}$/;
      const reg2 =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (!reg.test(value) && !reg2.test(value)) {
        callback("手机号或邮箱不正确");
      } else {
        callback();
      }
    };
    return {
      slideFlag: false,
      showConfirmModal: false,
      showLoginForm: false,
      showRegister: false,
      showFindPsw: false,
      isAutoLogin: false,
      hasMoveSlider: false,
      nowAccount: "",
      codeShowFlag: false,
      postForm: {
        username: "",
        password: "",
        code: "",
      },
      form: {
        user_phone: "",
        user_email: "",
        user_pwd: "",
        user_pwd_confirmation: "",
        argee: true,
        range: false,
        account: "", //
        code: "", //
      },
      rules: {
        user_phone: [
          {
            validator: phone,
            trigger: "blur",
          },
        ],
        user_email: [
          {
            validator: email,
            trigger: "blur",
          },
        ],
        user_pwd: [
          {
            validator: pass,
            trigger: "blur",
          },
        ],
        user_pwd_confirmation: [
          {
            validator: passConfirm,
            trigger: "blur",
          },
        ],
        argee: [
          {
            validator: argee,
            trigger: "change",
          },
        ],
        account: [
          {
            validator: account,
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      smsText: "发送验证码",
      smsDisabled: false,
      showErr: false,
    };
  },

  computed: {
    ...mapState("user", ["isLogin"]),
    findRules() {
      return this.setRules("find");
    },
    signinRules() {
      return this.setRules("registerForm");
    },
  },

  created() {
    this.$nextTick(() => {
      if (this.isLogin) {
        // 这个isLogin 是判断 登录弹窗还是注册弹窗 -_-:
        this.showConfirmModal = true; // 显示登陆提醒用
      } else {
        this.showRegister = true;
      }
    });
  },
  components: {
    JcRange,
  },
  methods: {
    setRules(ref) {
      return {
        user_phone: [
          {
            validator: (rule, value, callback) => {
              const reg = /^[0-9A-Za-z]{8,20}$/;
              if (!value) {
                callback("手机号不能为空");
              } else if (!reg.test(value)) {
                callback("手机号不正确");
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        user_email: [
          {
            validator: (rule, value, callback) => {
              // callback(); // TEST_ONLY
              const reg =
                /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
              if (!value) {
                callback("邮箱不能为空");
              } else if (!reg.test(value)) {
                callback("邮箱格式不正确");
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        user_pwd: [
          {
            validator: (rule, value, callback) => {
              // callback(); // TEST_ONLY

              // var reg = /^[0-9A-Za-z]{8,20}$/;
              // if (!value) {
              //   callback("密码不能为空");
              // } else if (!reg.test(value)) {
              //   callback("密码格式不正确，长度：8-20,数字、英文小写和大写字母");
              // } else {
              //   callback();
              // }

              if (!/[0-9]+/.test(value)) {
                return callback(new Error("密码必须包含数字或字母"));
              }
              if (!/[A-Za-z]+/.test(value)) {
                return callback(new Error("密码必须包含数字或字母"));
              }
              if (!/[!@#$%^&*(),]+/.test(value)) {
                return callback(new Error("密码必须包含特殊符号!@#$%^&*(),"));
              }
              if (value.length < 6) {
                callback(new Error("密码不能少于6位"));
              } else if (value.length > 20) {
                callback(new Error("密码不能超过20位"));
              } else {
                if (this.form.user_pwd_confirmation !== "") {
                  this.$refs[ref].validateField("user_pwd_confirmation");
                }
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        user_pwd_confirmation: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback("请再次输入密码");
              } else if (
                value !== this.form.user_pwd &&
                this.form.user_pwd !== ""
              ) {
                callback("两次输入的密码不相同！");
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        argee: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback("请阅读并选择同意协议");
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        account: [
          {
            validator: (rule, value, callback) => {
              const reg = /^[0-9A-Za-z]{8,20}$/;
              const reg2 =
                /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
              if (!reg.test(value) && !reg2.test(value)) {
                callback("手机号或邮箱不正确");
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      };
    },
    // 登录
  async handleLogin() {
    try{
      if (!this.slideFlag) return this.$message.error("请移动滑块到右侧！");
      if(this.codeShowFlag && !this.postForm.code) return this.$message.error("请输入验证码！");

      this.loading = true;
      let params = {};

      if(this.codeShowFlag){
        params = {
          account: this.postForm.username,
          code: this.postForm.code
        };
      }else{
        params = {
          account: this.postForm.username,
          user_pwd: this.$md5(this.postForm.password)
        };
      }

      const res = await this.$store.dispatch("user/login", params)
      const getInfo =  await this.$store.dispatch("user/getInfo");
      console.log('getInfo in async handleLogin', getInfo);
      window.location.reload();
      this.closeLoginForm();
    }catch(error){
          console.log('async handleLogin',error);
     } finally {
       this.loading = false;
     }
  },
    // 注册
    postRegister(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (!this.form.range) {
            this.showErr = true;
            return false;
          }
          register({
            // account: this.form.account,
            // code: this.form.code,
            user_phone: this.form.user_phone,
            user_email: this.form.user_email,
            user_pwd: this.$md5(this.form.user_pwd),
            user_pwd_confirmation: this.$md5(this.form.user_pwd_confirmation),
          }).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "注册成功",
                type: "success",
              });
              // 关闭所有
              this.closeAll();
            }
          });
        } else {
          console.log("error submit!!");
          if (!this.form.range) {
            this.showErr = true;
          }
          return false;
        }
      });
    },

    // 找回密码
    onFindPsw(name) {
      console.log("name", name);
      this.$refs[name].validate((valid) => {
        if (valid) {
          findPsw({
            account: this.form.account,
            code: this.form.code,
            // user_pwd: this.$md5(this.form.user_pwd),
            // user_pwd_confirmation: this.$md5(this.form.user_pwd_confirmation),
            // 后端要明文
            user_pwd: this.form.user_pwd,
            user_pwd_confirmation: this.form.user_pwd_confirmation,
          }).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "修改密码成功",
                type: "success",
              });
              // 关闭所有
              this.closeAll();
            }
          });
        } else {
          console.log("error submit!!");
          if (!this.form.range) {
            this.showErr = true;
          }
          return false;
        }
      });
    },

    //点击发送验证码按钮
    codeClick(flag) {
      if (flag) {
        this.codeShowFlag = true;
      } else {
        this.codeShowFlag = false;
      }
    },

    // 获取验证码
    getSms(type) {
      const reg = /^[0-9A-Za-z]{8,20}$/;
      const reg2 =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

      console.log("this.postForm.username", this.postForm.username);
      //判断登录还是修改密码
      if (type == "login") {
        this.nowAccount = this.postForm.username;
      } else {
        this.nowAccount = this.form.account;
      }

      if (!reg.test(this.nowAccount) && !reg2.test(this.nowAccount)) {
        this.$message({
          message: "请输入正确的手机或邮箱",
          type: "error",
        });
        return false;
      }

      let time = 60;
      getSmsCode({
        account: this.nowAccount,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "短信已发送",
            type: "success",
          });

          const clock = setInterval(() => {
            time = time - 1;
            if (time == 0) {
              clearInterval(clock);
              this.smsDisabled = false;
              this.smsText = "发送验证码";
            } else {
              this.smsDisabled = true;
              this.smsText = `${time}s后再次获取`;
            }
          }, 1000);
        }
      });
    },
    //
    handleMoveSlider() {
      this.slideFlag = true;
    },

    // 以下为点击事件
    onConfirm() {
      this.showConfirmModal = false;
      this.showLoginForm = true;
      setTimeout(() => {
        document.body.className = "el-popup-parent--hidden";
      }, 500);
    },

    closeAll() {
      this.$store.commit("user/SWITCH_LOGIN", false);
    },

    closeLoginForm() {
      console.log("close");
      this.showLoginForm = false;
    },

    openRegister() {
      this.showLoginForm = false;
      this.showRegister = true;
    },

    successRange(e) {
      this.form.range = true;
      this.showErr = false;
    },
  },
};
</script>

<style lang="less">
@import url("../../styles/login.css");

i.cus-close {
  color: #909399;
  cursor: pointer;

  &:hover {
    color: #409eff;
  }
}

.el-button.custom-button,
.el-button.custom-button:hover,
.el-button.custom-button:active {
  background: #898179 !important;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 16px;
  color: #fff !important;
  cursor: pointer;
  outline: none;
  border-color: #898179 !important;
}

.el-button.custom-button.is-disabled {
  background: #afa59b;
}

.el-dialog {
  border-radius: 10px !important;
}

.login_modal_title_text {
  float: left;
  font-size: 18px;
  color: #6e645a;
}

.jc-component__range {
  .jc-range,
  i {
    font-size: 14px;
    border-radius: 8px;
  }
}

.modal-confirm,
.modal-login {
  font-size: 20px;
  color: #6e645a;
  line-height: 1.5;

  span {
    font-size: 20px;
    color: #6e645a;
    line-height: 1.5;
  }

  button {
    display: block;
    padding: 0 15px;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    background-color: #42434b;
    color: #fff;
    margin: 15px auto 0;
    cursor: pointer;
  }
}

.modal-login {
  position: relative;

  .btn-close {
    cursor: pointer;
    transform: scale(1.5);
    position: absolute;
    top: 10px;
    right: 20px;
  }
}

.input-item {
  padding-bottom: 38px;

  input {
    width: 100%;
    height: 38px;
    font-size: 14px;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-bottom: 1px solid #b8937f;
    box-shadow: none;
    background-color: rgb(232, 240, 254) !important;
  }

  &.small-text {
    font-size: 12px;

    span {
      font-size: 12px;
    }

    .f-l {
      float: left;
    }

    .f-r {
      float: right;
      cursor: pointer;
    }
  }

  .submit-btn {
    background: #898179;
    display: block;
    width: 100%;
    height: 100%;
    line-height: 38px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    outline: none;
  }
}

.input-item {
  padding-bottom: 38px;

  input {
    width: 100%;
    height: 38px;
    font-size: 14px;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-bottom: 1px solid #b8937f;
    box-shadow: none;
    background-color: rgb(232, 240, 254) !important;
  }

  &.small-text {
    font-size: 12px;

    span {
      font-size: 12px;
    }

    .f-l {
      float: left;
    }

    .f-r {
      float: right;
      cursor: pointer;
    }
  }

  .submit-btn {
    background: #898179;
    display: block;
    width: 100%;
    height: 100%;
    line-height: 38px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    outline: none;
  }
}

/** */
.register-form {
  .register-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 35px;

    .input-item {
      padding: 0;
    }
  }

  .el-input__inner {
    border: 0;
    border-bottom: 1px solid #b8937f;
    box-shadow: none;
  }
}

.logincode {
  display: flex;
  padding-bottom: 38px;
  input {
    width: 100%;
    height: 38px;
    font-size: 14px;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-bottom: 1px solid #b8937f;
    box-shadow: none;
    background-color: rgb(232, 240, 254) !important;
  }

  .btn {
    flex: 0 0 100px;
    text-align: center;
    font-size: 12px;
    line-height: 38px;
    cursor: pointer;
    &.disabled {
      color: #999;
      cursor: not-allowed;
    }
  }
}
.text_class {
  font-size: 12px;
  margin-left: 5px;
}

/** */
.code {
  display: flex;
  .btn {
    flex: 0 0 100px;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    &.disabled {
      color: #999;
      cursor: not-allowed;
    }
  }
}
</style>
