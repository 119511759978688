
/**
 * @author:刘志江/zj
 * @date:2020-10-12
 * @descrtion:首页模块路由
 */

import { ABOUT_DEFAULT_META } from '../../constances/meta'

const aboutRouters = {
  path: '/aboutIndex',
  component: () => import('@/views/about/layout'),
  name: 'aboutIndex',
  redirect: '/about',
  meta: {
    icon: 'el-icon-folder-opened',
    ...ABOUT_DEFAULT_META
  },
  children: [
    // {
    //   path: '/about/:query',
    //   redirect: '/about/:query.html',
    //   // redirect: to => {
    //   //   return { path: '/about/:query.html', query: to.query }
    //   // },
    // },
    {
      path: '/about/:query.html',
      component: () => import('@/views/about/index/index'),
      name: 'about',
      meta: {
        noCache: true,
        ...ABOUT_DEFAULT_META

      }
    }
  ]
}

export default aboutRouters
