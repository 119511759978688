<template>
  <header>
    <TopBar />
    <!-- <TopMenuOld /> -->
    <TopMenu />
  </header>
</template>

<script>

/**
 * 顶部栏
 * zj 2020-10-13
 *   
 */
import TopBar from "@/components/header/topbar";
// import TopMenuOld from "@/components/header/topmenu.old";
import TopMenu from "@/components/header/topmenu";
export default {
  name: "head-bar",
  components: {
    TopBar,
    // TopMenuOld,
    TopMenu,
  }
};
</script>

<style lang="less">
/* <!-- 以下是页面的专用css引入 --> */
// @import "../../styles/animate.min.css";
// @import "../../styles/index.less";
// @import "../../styles/tipModal.less";

// @import "../../styles/nav.less";
</style>