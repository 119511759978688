import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}


const AccountKey = 'account'

export function getAccount() {
  return Cookies.get(AccountKey)
}

export function setAccount(account) {
  return Cookies.set(AccountKey, account)
}

export function removeAccount() {
  return Cookies.remove(AccountKey)
}

// const TdkKey = 'tdk'

// export function getTDK() {
//   return Cookies.get(TdkKey)
// }

// export function setTDK(tdk) {
//   console.log("setTDK", tdk)
//   return Cookies.set(TdkKey, tdk)
// }

// export function removeTDK() {
//   return Cookies.remove(TdkKey)
// }