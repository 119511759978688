import SETINFO from '@/constances/setInfo'

const state = {
  aliveParams: {},
  params: {},
  brands: {},
}

const mutations = {
  SET_ALIVE_PARAMS(state, aliveParams) {
    state.aliveParams = aliveParams;
  },
  SET_PARAMS(state, params) {
    state.params = params;
  },
  SET_BRANDS(state, brands) {
    state.brands = brands;
  },
}

const actions = {
  async setAliveParams({ commit}) {
    try {
      const res = await SETINFO.API.getParamsWithAliveValues({byname:SETINFO.PARAM.BYNAME.JINDUO_ID});
      console.log('SETINFO.API.getParamsWithAliveValues', res);
      const params = (res?.data || []).reduce((result,param)=>{
        const valuesMap = (param.values || []).reduce((resultValues,value)=>{
          resultValues[value.id] = value.name;
          return resultValues;
        },{})
        result[param.byname] = {...param,valuesMap};
        return result;
      },{})
      commit('SET_ALIVE_PARAMS', params);
    } catch (error) {
      console.log(`error SETINFO.API.getParamsWithAliveValues`, error);
    }
  },
  async setParams({ commit}) {
    try {
      const res = await SETINFO.API.getParamsWithValues({});
      console.log('SETINFO.API.getParamsWithValues', res);
      const params = (res?.data || []).reduce((result,param)=>{
        const valuesMap = (param.values || []).reduce((resultValues,value)=>{
          resultValues[value.id] = value.name;
          return resultValues;
        },{})
        result[param.byname] = {...param,valuesMap};
        return result;
      },{})
      commit('SET_PARAMS', params);
    } catch (error) {
      console.log(`error SETINFO.API.getParamsWithValues`, error);
    }
  },
  async setBrands({ commit}) {
    try {
      const res = await SETINFO.API.getBrands({
        status: 1,
        page_size: 999,
      });
      console.log('SETINFO.API.getBrands', res);
      const brands = (res?.data?.data || []).reduce((result,brand)=>{
        result[brand.byname] = brand;
        return result;
      },{})
      commit('SET_BRANDS', brands);
    } catch (error) {
      console.log(`error SETINFO.API.getBrands`, error);
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}