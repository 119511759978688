import request from '@/utils/request'
import { API_PRIFIX } from '@/constances/server'
const platform = {
  '酷家乐': `kujiale`,
  'OSS': `jinduoOSS`,
}
const source = {
  [`用户方案`]: `${platform['酷家乐']}/design`,
  [`被删除方案`]: `${platform['酷家乐']}/designDeleted`,
  [`获取iframe的地址`]: `${platform['酷家乐']}/iframeSrc`,
  [`渲染图`]: `${platform['酷家乐']}/renderpic`,
  [`户型`]: `${platform['酷家乐']}/floorplan`,
  [`顾客方案`]: `customerScheme`,
  [`顾客方案编号`]: `customerSchemeNumber`,
  [`清单`]: `${platform['酷家乐']}/listing`,
  [`装修清单`]: `hard/outfit/detail`,
  [`导购`]: `${platform['OSS']}/guides`,
  [`预约设计师`]: `designAppointment`,
  [`绑定酷家乐id`]: `bindKuAlbum`,
}

/**
 * 用户方案
*/
// 用户方案 - 列表
export function getMySchemes(params) {
  return request({
    url: `${API_PRIFIX}/${source[`用户方案`]}`,
    method: 'get',
    params
  })
}
// 用户被删除方案 - 列表
export function getMyDelSchemes(params) {
  return request({
    url: `${API_PRIFIX}/${source[`被删除方案`]}`,
    method: 'get',
    params
  })
}
// 用户方案 - 基本信息
export function getMySchemeInfo({id}) {
  return request({
    url: `${API_PRIFIX}/${source[`用户方案`]}/${id}/basic`,
    method: 'get',
  })
}
// 用户方案 - 设计案例信息
export function getMySchemeAlbum({id}) {
  return request({
    url: `${API_PRIFIX}/${source[`用户方案`]}/${id}/album`,
    method: 'get',
  })
}
// 用户方案 - 装修清单
export function getMySchemeGoods({id}) {
  return request({
    url: `${API_PRIFIX}/${source[`用户方案`]}/${id}/item`,
    method: 'get',
  })
}
// 用户方案 - 设计案例信息编辑
export function editMySchemeAlbum({id,...data}) {
  return request({
    url: `${API_PRIFIX}/${source[`用户方案`]}/${id}/album`,
    method: 'put',
    data
  })
}
// 用户方案 - 复制
export function copyMyScheme({id}) {
  return request({
    url: `${API_PRIFIX}/${source[`用户方案`]}/${id}/copy`,
    method: 'post',
  })
}
// 用户方案 - 删除
export function delMyScheme({id}) {
  return request({
    url: `${API_PRIFIX}/${source[`用户方案`]}/${id}`,
    method: 'delete',
  })
}
// 用户方案 - 已删除方案 - 恢复
export function recoveryMyScheme({planId}) {
  return request({
    url: `${API_PRIFIX}/${source[`用户方案`]}/${planId}/recovery`,
    method: 'patch',
  })
}
// 获取iframe的地址
export function getIframeSrc({dest,...params}) {
  return request({
    url: `${API_PRIFIX}/${source[`获取iframe的地址`]}/${dest}`,
    method: 'get',
    params
  })
}
// 用户方案 - 批量恢复
export function designBatchRecovery(data) {
  return request({
    url: `${API_PRIFIX}/${source[`用户方案`]}/designBatchRecovery`,
    method: 'post',
    data
  })
}
/**
 * 渲染图
*/
// 渲染图 - 列表
export function getRenderings(params) {
  return request({
    url: `${API_PRIFIX}/${source[`渲染图`]}`,
    method: 'get',
    params
  })
}
// 渲染图 - 生成全屋漫游图
export function createPanorama(data) {
  return request({
    url: `${API_PRIFIX}/${source[`渲染图`]}/pano`,
    method: 'post',
    data
  })
}
// 渲染图 - 删除
export function delRendering({id}) {
  return request({
    url: `${API_PRIFIX}/${source[`渲染图`]}/${id}/deletion`,
    method: 'delete',
  })
}

/**
 * 户型
*/
// 户型 - 基本信息
export function getFloorplan({id}) {
  return request({
    url: `${API_PRIFIX}/${source[`户型`]}/${id}/basic`,
    method: 'get',
  })
}
// 户型 - 房间结构化信息
export function getStructure({id,...params}) {
  return request({
    url: `${API_PRIFIX}/${source[`户型`]}/${id}/room/structure`,
    method: 'get',
    params
  })
}

/**
 * 顾客方案
*/
// 顾客方案 - 列表
export function getClientSchemes(params) {
  return request({
    url: `${API_PRIFIX}/${source[`顾客方案`]}`,
    method: 'get',
    params
  })
}
// 顾客方案 - 详情
export function getClientScheme({id}) {
  return request({
    url: `${API_PRIFIX}/${source[`顾客方案`]}/${id}`,
    method: 'get',
  })
}
// 顾客方案 - 编号
export function getClientSchemeNum() {
  return request({
    url: `${API_PRIFIX}/${source[`顾客方案编号`]}`,
    method: 'get',
  })
}
// 顾客方案 - 编辑
export function putClientScheme(data) {
  return request({
    url: `${API_PRIFIX}/${source[`顾客方案`]}`,
    method: 'put',
    data
  })
}

/**
 * 装修清单
*/
// 清单管理 - 硬装主材数据（装修清单）
export function getListing({id,...params}) {
  return request({
    url: `${API_PRIFIX}/${source[`清单`]}/${id}/${source[`装修清单`]}`,
    method: 'get',
    params
  })
}
// 清单管理 - 编辑清单
export function putListing(data) {
  return request({
    url: `${API_PRIFIX}/${source[`清单`]}`,
    method: 'put',
    data
  })
}
// 导购
export function getSalesmen(params) {
  return request({
    url: `${API_PRIFIX}/${source[`导购`]}`,
    method: 'get',
    params
  })
}

/**
 * 预约设计师
*/
// 预约设计师 - 获取设计师预约列表
export function getDesignAppointments(params) {
  return request({
    url: `${API_PRIFIX}/${source[`预约设计师`]}`,
    method: 'get',
    params
  })
}
// 预约设计师 - 获取设计师预约详情
export function getDesignAppointment({id}) {
  return request({
    url: `${API_PRIFIX}/${source[`预约设计师`]}/${id}`,
    method: 'get',
  })
}
// 预约设计绑定酷家乐id
export function bindKuAlbum({id,...data}) {
  return request({
    url: `${API_PRIFIX}/${source[`预约设计师`]}/${source[`绑定酷家乐id`]}/${id}`,
    method: 'post',
    data
  })
}

// 完成预约单
export function finishAppointment({id}) {
  return request({
    url: `${API_PRIFIX}/${source[`预约设计师`]}/finish/${id}`,
    method: 'post',
  })
}
