import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
// filter
import * as filters from './filters' // global filters
// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

console.log('2022-0319-1736');

Vue.config.productionTip = false
// elementui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
// md5
import md5 from 'js-md5'
Vue.prototype.$md5 = md5
// 使用 this.$md5('This is encrypted content')  // 6f43dd5db792acb25d6fe32f3dddac70
Vue.prototype.$cookies = VueCookies
// amap
import VueAMap from 'vue-amap'
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: 'YOUR_KEY', // 你在高德申请的key
  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor',
    'AMap.Geolocation'
  ],
  v: '1.4.4'
})

console.log('=1=')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
