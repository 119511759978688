import _ from 'lodash'
import Qs from 'qs'
import {
  IMAGE_PREFIX, RESOURCE_API_URL
} from '@/constances/server'

/**
 * 过滤空值
 * @params  Object  请求参数对象
 * @needs   Array   参数白名单
 *    @pattern  'key'         普通参数
 *    @pattern  'path.key'    深层次参数
 * @slimStr Array   空字符串参数黑名单
 * @slimArr Array   空数组参数黑名单
 * @path    String   深层次过滤路径
*/
export function slim(params, needs ,slimStr, slimArr, path) {
  params = _.cloneDeep(params);
  Object.keys(params).forEach(key => { // 循环请求参数对象
    if (Array.isArray(params[key])) { // 判断参数值是数组
      params[key] = params[key].reduce((result,item)=>{ // 循环参数值数组
        if (item !== undefined && item !== null) { // 处理非空数组项，其余过滤
          result.push(
            typeof item === 'object' ? // 判断数组项为对象则带参深入过滤，否则直接取值
              slim(
                item,
                needs,
                slimStr,
                slimArr, 
                (path?`${path}.${key}`:key)
              ) : 
              item 
          )
        }
        return result
      },[])
    }
    if (
      params[key] === undefined || 
      params[key] === null ||
      (needs && needs.length && !needs.includes(path?`${path}.${key}`:key)) ||
      (slimStr && slimStr.length && slimStr.includes(key) && params[key] === '') || 
      (Array.isArray(params[key]) && slimArr && slimArr.length && slimArr.includes(key) && !params[key].length)
    ) {
      delete params[key]
    }
  })
  if (Object.keys(params).length) {
    return params
  } else {
    return null
  }
}

export function formatData(data, needs ,slimStr, slimArr, path){
  return Qs.stringify(slim(data, needs ,slimStr, slimArr, path), { arrayFormat: 'brackets' })
}

/**
 * w 宽
 * h 高
*/
export function getThumbnail(url, params) {
  if (url && !url.startsWith(`data`)) {
    const origin = (url.startsWith(`http`) ? '' : IMAGE_PREFIX) + url.split('?')[0];
    let query = `?x-oss-process=image/resize`;
    if (params && Object.keys(params).length) {
      query += Object.keys(params).reduce((result, key) => {result += `,${key}_${params[key]}`;return result;}, '');
    } else {
      query += `,m_fill,h_200,w_200`;
    }
    return `${origin}${query}`;
  } else {
    return url
  }
}