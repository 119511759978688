<template>
  <footer>
    <!-- 底部 -->
    <section class="footer cell">
      <div class="container">
        <div class="footer_left">
          <div class="left_list">
            <span class="list_title">走进金舵</span>
            <router-link class="list_link" to="/about/intro.html">认识金舵</router-link>
            <router-link class="list_link" to="/about/progress.html">品牌历程</router-link>
            <router-link class="list_link" to="/about/news.html?type=401">新闻动态</router-link>
          </div>
          <div class="left_list product_list">
            <span class="list_title">产品系列</span>
            <a v-for="(val, index) in series" :key="index" class="list_link" @click="toSeriesPage(val.id)">{{ val.name
              }}</a>

            <!-- {/foreach} -->
          </div>
          <!-- <div class="left_list">
            <span class="list_title">装修社区</span>
            <router-link class="list_link" to="/bbs?sid=1">新闻快讯</router-link>
            <router-link class="list_link" to="/bbs?sid=2">装修攻略</router-link>
            <router-link class="list_link" to="/bbs?sid=3">家居设计</router-link>
          </div> -->
          <!-- <div class="left_list">
            <span class="list_title">经销门店</span>
            <router-link class="list_link" to="/store/0-0-0">全国网点</router-link>
            <a class="list_link" href="javascript:void(0)" @click="handleNotOpen('经销加盟')">经销加盟</a>
            <a class="list_link" href="javascript:void(0)" @click="handleNotOpen('1对1设计')">1对1设计</a>
          </div> -->
          <div class="left_list">
            <span class="list_title">经销支持</span>
            <!-- <a
              class="list_link"
              href="javascript:void(0)"
              @click="handleNotOpen('物流配送')"
              >物流配送</a
            > -->
            <router-link class="list_link" to="/store/0-0-0">全国网点</router-link>
            <router-link class="list_link" to="/download">资料下载</router-link>
            <!-- <a class="list_link" href="javascript:void(0)" data-open="loginModal">注册登录</a> -->
            <!-- <a
              class="list_link"
              href="javascript:void(0)"
              @click="handleNotOpen('售后服务')"
              >售后服务</a
            >
            <a
              class="list_link"
              href="javascript:void(0)"
              @click="handleNotOpen('常见问题')"
              >常见问题</a
            > -->
          </div>
          <div class="copyright_info">
            <span>Copyright © 2015 佛山市金舵陶瓷有限公司</span>
            <a href="http://beian.miit.gov.cn" target="_blank"> 粤ICP备15078844号
            </a>
          </div>
        </div>
        <div class="footer_right">
          <div class="phone_email">
            <span>电话：0757-85310888</span>
            <!-- <span>邮箱：400-XXX-XXX</span> -->
          </div>
          <div class="address">
            地址：广东省佛山市禅城区南庄大道149号金舵领航馆
          </div>
          <div class="friend_link">
            <span>友情链接：</span>
            <a href="http://jinduo.88ip.cn:89/login/Login.jsp?logintype=1" target="_blank">OA办公</a>
            <!-- <a href="http://www.sjyuan.cn/" target="_blank">金舵设计园</a> -->
            <!-- <a href="http://www.homenice.com.cn/" target="_blank">地板十大品牌</a> -->
            <!-- <a href="http://www.jdz66.com/" target="_blank">景德镇陶瓷</a> -->
            <a href="https://www.ceramicschina.com/" target="_blank">陶瓷十大品牌</a>
          </div>

          <div class="contact_info_icon">
            <a href="https://weibo.com/jinduoblog">
              <img
                src="https://jinduo-marketing.oss-cn-shenzhen.aliyuncs.com/upload/20241007/3d9642a3b1ee12a15b14166b113a087c.png"
                alt="微博">
            </a>
            <!-- <a href="javascript:void(0);">
              <img
                src="https://jinduo-marketing.oss-cn-shenzhen.aliyuncs.com/upload/20241007/cb2f89577b0ee4314328ad7ba2ef58c4.png"
                alt="微信小程序">
              <img class="code"
                src="https://jinduo-marketing.oss-cn-shenzhen.aliyuncs.com/upload/20241007/5bd38a359a8f5cfe467576b370e9d492.jpg"
                alt="微信小程序">
            </a> -->
            <a target="blank" href="tencent://message/?uin=1915573298&amp;Site=网站客服&amp;Menu=yes" rel="nofollow">
              <img
                src="https://jinduo-marketing.oss-cn-shenzhen.aliyuncs.com/upload/20241007/fe324ea02ff4c64098ce63cf8f84130d.png"
                alt="QQ">
            </a>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "topMenu",
  data () {
    return {

    };
  },
  computed: {
    ...mapState('params', ['aliveParams']),
    series () {
      return this.aliveParams?.['series_classification']?.values || [];
    },
  },
  created () {
  },
  mounted () {
    this.$nextTick(() => { });
  },
  methods: {
    handleNotOpen (name) {
      this.$notify({
        title: "暂未开放",
        message: `${name}暂未开放！`,
        type: "error",
      });
    },
    toSeriesPage (id) {
      return this.$router.push(`/product/list/${id}-0-0-0-0/`)
    },
  },
};
</script>

<style lang="less">
@import "../../styles/footer.less";
@import "../../styles/slideBar_menu.less";
</style>

<style lang="less" scoped>
@hover_nav_color: #b8937f;

.copyright_info {
  margin-bottom: 20px;

  span,
  a {
    font-size: 12px;
    color: #aaa;
  }

  a:hover {
    color: @hover_nav_color;
  }
}
</style>