import _ from 'lodash'
import defaultSettings from '@/settings'
import { COMMON_DESCRIPTION, COMMON_KEYWORDS } from '../constances/meta'

const title = defaultSettings.title

export default function setPageTitleAndMeta(pageTitle, contents = {}) {
  const _title = pageTitle ? `${pageTitle} - ${title}` : `${title}`
  document.title = _title
  if (!_.isEmpty(contents)) {
    Object.keys(contents).map(item => {
      document.querySelector(`meta[name=${item}]`).content = contents[item] || COMMON_DESCRIPTION
    })
  } else {
    document.querySelector("meta[name='keywords']").content = COMMON_KEYWORDS
    document.querySelector("meta[name='description']").content = COMMON_DESCRIPTION
  }
}


export function setTDK(options) {
  document.title = options.seo_title || defaultSettings.title
  document.querySelector("meta[name='keywords']").content = options.seo_keyword || COMMON_KEYWORDS
  document.querySelector("meta[name='description']").content = options.seo_description || COMMON_DESCRIPTION
}
