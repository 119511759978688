import {
  login,
  logout,
  getInfo
} from '@/api/user'
import {
  getMyDesignerInfo
} from '@/api/designer'
import {
  getToken,
  setToken,
  removeToken,
  setAccount,
  removeAccount
} from '@/utils/auth'
import {
  IMAGE_PREFIX
} from "@/constances/server.js";

/*   import router, {
    resetRouter
  } from '@/router' */

const state = {
  token: getToken(),
  avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
  city: null,
  id: '',
  store_id: '',
  email: '',
  real_name: '',
  roles: [],
  user_email: "",
  user_head: "",
  user_id: 0,
  username: '',
  user_name: "",
  user_phone: "",
  user_role: "",
  isLogin: true,  // 这个isLogin 是判断 登录弹窗还是注册弹窗 -_-: 不是登录状态 ！！！
  showConfirmModal: false, //登录确认对话框
  path: null,
  current_nav: 1,
  keyword: '',
  designer: null,
  commentBtn: false,
}

const mutations = {
  TOGGLE_COMMENT(state, bool) {
    state.commentBtn = bool;
  },
  CHANGE_NAV(state, value) {
    state.current_nav = value;
  },
  SWITCH_LOGIN(state, value) {
    state.showConfirmModal = value;
    state.isLogin = true
  },
  CHECK_REGISTER(state, value) {
    state.isLogin = false
  },
  SET_TOKEN: (state, token) => {
    state.showConfirmModal = false;
    state.isLogin = true
    state.token = token
  },
  SET_CITY: (state, city) => {
    state.city = city
  },
  SET_DESIGNER: (state, designer) => {
    state.designer = designer
  },
  LOGOUT: (state) => {
    removeToken()
    removeAccount()
    state.isLogin = true;   // 这个isLogin 是判断 登录弹窗还是注册弹窗 -_-: 不是登录状态 ！！！
    state.userInfo = null;
    state.designer = null;
    state.token = null;
    state.roles = null;
    state.id = '';
    state.store_id = '';
    state.email = '';
    state.real_name = '';
    state.user_email = "";
    state.user_head = "";
    state.user_id = null;
    state.username = '';
    state.user_name = "";
    state.user_phone = "";
    state.user_role = "";
  },
  SET_USER_INFO: (state, info) => {
    console.log('SET_USER_INFO', info)
    state.userInfo = info;
    Object.keys(info).map(key => {
      state[key] = info[key]
      if (key == 'user_head') {
        if (state[key]) {
          state[key] = state[key] && state[key].indexOf("http") > -1 ? state[key] : `${IMAGE_PREFIX}` + state[key];
        } else {
          state[key] = 'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1618065806,14298536&fm=26&gp=0.jpg'
        }
        // state[key] = state[key] && state[key].indexOf("http") > -1 ? state[key] : 'https://resource.jinduo.com/' + state[key];
      }

    })
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_PATH: (state, value) => {
    // console.log('SET_PATH',{state, value});
    state.path = value;
  },
  CHANGE_KEYWORD(state, value) {
    state.keyword = value;
  }
}

const actions = {
  toggleComment({ commit}, bool) {
    commit('TOGGLE_COMMENT', bool)
  },
  // user login
  login({
    commit
  }, userInfo) {
    return new Promise((resolve, reject) => {
      /* start */
      /*  const data = {
          id: 13,
          token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIiLCJhdWQiOiIiLCJpYXQiOjE1OTkxMTUwOTksIm5iZiI6MTU5OTExNTA5OSwiZXhwIjoxOTE0NDc1MDk5LCJ1aWQiOjEzfQ.jkfJrRrpYzMV0k7kE3w6ukaBjHIOYKo6ZxckiCe8y9U"
  
        };
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve() */

      /* end */

      login(userInfo).then(response => {
        const {
          data
        } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({
    commit,
    state
  }) {
    console.log(state)
    console.log('dispatch user/getInfo', state.token)
    return new Promise((resolve, reject) => {
      /*  */
      // 暂时只有一个权限 固定role
      /*  const _roles = ['admin'];
        const data = {
          account: "stire",
          headimgurl: null,
          id: 13,
          name: "测试张三",
          role: {
            id: 9,
            name: "普通管理员"
          },
          store_id: 3,
          store_name: "金舵瓷砖狮山店"
        };
        const {
          roles,
          ...rest
        } = data
        // roles must be a non-empty array
        if (!_roles || _roles.length <= 0) {
          reject('获取用户信息： 用户权限获取失败!')
        }
  
        commit('SET_ROLES', _roles)
        commit('SET_USER_INFO', rest)
        resolve({
          ...data,
          roles: _roles
        }) */
      /* 1 */

      getInfo(state.token).then(response => {
        const {
          data
        } = response

        if (!data) {
          reject('用户验证失效，请重新登录。')
        }

        const {
          roles,
          designer_detail,
          ...rest
        } = data
        console.log('getInfo designer_detail', designer_detail);

        getMyDesignerInfo().then(res=>{
          const designer = res?.data;
          console.log('res getMyDesignerInfo', designer);
          if (designer?.designer_detail?.id) {
            commit('SET_DESIGNER', designer)
          }
        }).catch(error=>{
          console.log('error getMyDesignerInfo', error);
        })

        // 暂时只有一个权限 固定role
        const _roles = roles || ['admin']

        // roles must be a non-empty array
        if (!_roles || _roles.length <= 0) {
          reject('获取用户信息： 用户权限获取失败!')
        }

        commit('SET_ROLES', _roles)
        commit('SET_USER_INFO', rest)
        if (data.user_name) {
          setAccount(data.user_name)
        }
        resolve({
          ...data,
          roles: _roles
        })
      }).catch(error => {
        console.log('error in getInfo promise', error);
        reject(error)
      })
    })
  },

  // user logout
  logout({
    commit,
    state,
    dispatch
  }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => { // 登录状态才能请求logout
        commit('LOGOUT')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      commit('LOGOUT')
      resolve()
    })
  },

  // setCity
  setCity({ commit }, city) {
    return new Promise(resolve => {
      commit('SET_CITY', city)
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({
    commit,
    dispatch
  }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)
    const {
      roles
    } = await dispatch('getInfo')

    //   resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, {
      root: true
    })
    // dynamically add accessible routes
    //   router.addRoutes(accessRoutes)

    // reset visited views and cached views
    /* dispatch('tagsView/delAllViews', null, {
      root: true
    }) */
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}